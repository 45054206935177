import React, { useState, useEffect } from "react";
import { List, ListItem, Card, IconButton, Container } from "luna-react";
import { Plus, Minus } from "luna-icons";
import HierarchyForm from "./HierarchyForm";
import "./index.scss";

const HierarchyList = ({
  id,
  hierarchyType,
  parentId,
  heading,
  parentHeading,
  items,
  handleItemSelection,
  notifyFormSubmissionDone
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const initialForm = {
    id: null,
    name: "",
    parentId: parentId,
    classCodePrefix:"",
    hierarchyCode: "",
    isActive: true,
    mode: "Add"
  };

  const [form, setForm] = useState(initialForm);

  // Change the form to Add mode if its List is reset by its parent Hierarchy
  useEffect(() => {
    if (items != null && items.find(x => x.selected) == null) {
      setForm(initialForm);
    }
  }, [items]);

  const setItemClassName = item => {
    let className = "hierarchy-item";

    if (item.selected) {
      className += " hierarchy-item-selected";
    }

    if (!item.isActive) {
      className += " hierarchy-item-notActive";
    }

    return className;
  };

  const populateForm = itemId => {
    const selectedItem = items.find(x => x.id === itemId);
    setForm({
      id: itemId,
      name: selectedItem.name,
      parentId: parentId,
      classCodePrefix:"",
      hierarchyCode: selectedItem.hierarchyCode,
      isActive: selectedItem.isActive,
      mode: "Edit"
    });
  };

  return (
    <Container id={`hierarchy-list-${id}`}>
      <Card>
        <div className="ln-o-clearfix" data-reactroot="">
          <div className="ln-c-button-group__primary">
            <IconButton
              variant="filled"
              hideLabel
              label=""
              onClick={() => {
                setIsFormVisible(!isFormVisible);
              }}
            >
              {isFormVisible ? <Minus /> : <Plus />}
            </IconButton>
          </div>
          <div className="ln-c-button-group__secondary">
            <h5 className="label">{heading}</h5>
          </div>
        </div>
      </Card>
      <br />
      <Card className="hierarchy-item-container">
        <List type="bare" spaced>
          {items &&
            items.map(item => (
              <ListItem key={item.id} type="bare" className="no-padding">
                <a
                  className={setItemClassName(item)}
                  href="#"
                  onClick={event => {
                    populateForm(item.id);
                    handleItemSelection(hierarchyType, item.id);
                    event.preventDefault();
                  }}
                >
                  {item.name}{(hierarchyType==="ProductClass"||hierarchyType==="Option")?" ("+item.hierarchyCode+")":null}
                </a>
              </ListItem>
            ))}
        </List>
      </Card>
      <br />
      {isFormVisible && (
        <Card>
          <HierarchyForm
            hierarchyType={hierarchyType}
            parentHeading={parentHeading}
            parentId={parentId}
            displayFormData={form}
            notifyFormSubmissionDone={notifyFormSubmissionDone}
          />
        </Card>
      )}
    </Container>
  );
};

export default HierarchyList;
