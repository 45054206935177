import { api } from "../api/api";

const productHierarchyApi = `${window.appConfig.rootUrl}/ProductHierarchies/`;

export function useGetProductHierarchies() {
  const [{ data, isLoading, isError }, setQueryData] = api.useDataFetch(
    productHierarchyApi
  );

  return [{ data, isLoading, isError }, setQueryData];
}

export function useSaveProductHierarchy() {
  const [
    { isSaved, isError, errorCode, errorMessage },
    setForm
  ] = api.useDataSave(productHierarchyApi);

  return [{ isSaved, isError, errorCode, errorMessage }, setForm];
}

export function useGetClassHierarchyCode() {
  const [{ data, isLoading, isError }, setQueryData] = api.useDataFetch(
    `${productHierarchyApi}GetClassHierarchyCode`
    );

  return [{ 
    classHierarchyCode: data, 
    classCodeLoading:isLoading, 
    classCodeError:isError }, 
    {setClassHierarchyCode:setQueryData}];
}

export function useOptionGetHierarchyCode() {
  const [{ data, isLoading, isError }, setQueryData] = api.useDataFetch(
    `${productHierarchyApi}GetOptionHierarchyCode`
  );

  return [{ 
    optionCodeData: data, 
    optionCodeLoading : isLoading, 
    optionCodeError : isError}, 
    {setOptionHierarchyCode:setQueryData}];
}