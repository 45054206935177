import React, { Fragment, useState, useEffect } from "react";
import { SelectField, GridWrapper, GridItem, FilledButton } from "luna-react";
import * as utils from "../../shared/Utils";
import strings from "../../../res/strings";
import { useGetProductHierarchies } from "../../../api/productHierarchyService";
import { HierarchyType } from "../../../res/enums";
import jp from "jsonpath";
import { useGetDownload } from "../../../api/mappingService";
import LoadingNotification from "../../shared/LoadingNotification";

const ProductHierarchySelector = ({ isPageLoading }) => {
  const [{ data, isLoading, isError }, setQuery] = useGetProductHierarchies();
  const [productHierarchyData, setProductHierarchyData] = useState();
  const [
    { exportData, isDownloading, isDownloaded, isDownLoadError },
    setExportData
  ] = useGetDownload();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productClass, setProductClass] = useState([]);
  const initialHierarchy = {
    categoryId: null,
    subCategoryId: null,
    productClassId: null
  };
  const [selectedHierarchy, setSelectedHierarchy] = useState(initialHierarchy);
  const initialError = { categoryId: null, subCategoryId: null };
  const [error, setError] = useState(initialError);

  useEffect(() => {
    setQuery(`isActive=true&ts=${Math.random()}`);
  }, []);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isDownLoadError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDownLoadError]);

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      } else {
        setProductHierarchyData(data);
        const localCategories = data.map(x => {
          return { value: x.id, label: x.isActive ? x.name : x.name + " *" };
        });
        setCategory(localCategories);
        setSubCategory([]);
        setProductClass([]);
        setSelectedHierarchy(initialHierarchy);
      }
    }
  }, [data]);

  useEffect(() => {
    if (exportData) {
      fileDownload();
    }
  }, [exportData]);

  const handleItemSelection = (hierarchyTypeValue, itemId) => {
    const selectedProductHierarchyId = itemId || null;

    switch (hierarchyTypeValue) {
      case HierarchyType.CATEGORY:
        setSelectedHierarchy({
          categoryId: selectedProductHierarchyId,
          subCategoryId: null,
          productClassId: null
        });

        if (selectedProductHierarchyId) {
          const subCategoryItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedProductHierarchyId})].subCategory`
          )[0];
          setSubCategory(
            subCategoryItems != null
              ? subCategoryItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *"
                  };
                })
              : []
          );
        } else {
          setSubCategory([]);
        }

        setProductClass([]);
        break;
      case HierarchyType.SUBCATEGORY:
        setSelectedHierarchy({
          ...selectedHierarchy,
          subCategoryId: selectedProductHierarchyId,
          productClassId: null
        });

        if (selectedProductHierarchyId) {
          const productClassItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedProductHierarchyId})].productClass`
          )[0];
          setProductClass(
            productClassItems != null
              ? productClassItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *"
                  };
                })
              : []
          );
        } else {
          setProductClass([]);
        }

        break;
      case HierarchyType.PRODUCTCLASS:
        setSelectedHierarchy({
          ...selectedHierarchy,
          productClassId: selectedProductHierarchyId
        });
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let passed = true;
    let _error = initialError;

    if (!selectedHierarchy.categoryId) {
      _error.categoryId = "Category is required";
      passed = false;
    }

    setError(_error);

    return passed;
  };

  const handleDownload = () => {
    if (validateForm()) {
      let productHierarchyId = selectedHierarchy.categoryId;

      if (selectedHierarchy.subCategoryId) {
        productHierarchyId = selectedHierarchy.subCategoryId;
      }

      if (selectedHierarchy.productClassId) {
        productHierarchyId = selectedHierarchy.productClassId;
      }
      setExportData({ productHierarchyId, timestamp: Math.random() });
    }
  };

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };

    const filename = `Mapping Document - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";

    const file = new Blob([exportData], blobType);

    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    let a = document.createElement("a");

    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  return (
    <Fragment>
      {isDownloading && !isDownloaded && (
        <LoadingNotification notificationOpen={true} />
      )}

      <GridWrapper gutterSize="zero" id="product-hierarchy-selector">
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="category-select-field"
            name="category-select-field"
            label="Category *"
            value={selectedHierarchy.categoryId || ""}
            error={error.categoryId}
            options={category}
            onChange={event =>
              handleItemSelection(HierarchyType.CATEGORY, event.target.value)
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="sub-category-select-field"
            name="sub-category-select-field"
            label="Sub Category"
            error={error.subCategoryId}
            options={subCategory}
            onChange={event =>
              handleItemSelection(HierarchyType.SUBCATEGORY, event.target.value)
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="product-class-select-field"
            name="product-class-select-field"
            label="Class"
            options={productClass}
            onChange={event =>
              handleItemSelection(
                HierarchyType.PRODUCTCLASS,
                event.target.value
              )
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft button-container no-padding-left no-padding-right no-padding-bottom"
        >
          <FilledButton
            id="report-mapping--download-button"
            onClick={() => handleDownload()}
            disabled={isPageLoading}
            fullWidth
          >
            Download
          </FilledButton>
        </GridItem>
      </GridWrapper>
    </Fragment>
  );
};

export default ProductHierarchySelector;
