import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { TableContainer, TableHeader, TableHeaderCell, TableBody, TableCell, TableHeaderRow, TableRow } from "luna-react";

const GeneralDocumentMapping = (generalDocumentModel) =>{
  const [localGeneralDocument, setLocalGeneralDocument]=useState();

  useEffect(()=>{
    if(generalDocumentModel){
        setLocalGeneralDocument(generalDocumentModel.generalDocumentModel);
    }
  },[generalDocumentModel]);

  return(
    <Card>
      <TableContainer responsive>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell scope="colgroup">Standard</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Identifier</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Description</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Note</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Applies To</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {localGeneralDocument &&
           (
              <TableRow key={Math.random()} className="general-document-mapping-cell">
                <TableCell>{localGeneralDocument.standard}</TableCell>
                <TableCell>{localGeneralDocument.identifier}</TableCell>
                <TableCell>{localGeneralDocument.description}</TableCell>
                <TableCell>{localGeneralDocument.note}</TableCell>
                <TableCell>{localGeneralDocument.appliesTo}</TableCell>
              </TableRow>
            )}
        </TableBody>
      </TableContainer>
    </Card>
  );
    
}
export default GeneralDocumentMapping;