import React, { Fragment, useEffect, useState } from "react";
import RegulationMapping from "./RegulationMapping";
import GeneralDocumentMapping from "./GeneralDocumentMapping";
import ProductHierarchySelector from "./ProductHierarchySelector";
import "./index.scss"
import ReverseMappingComponent from "./ReverseMapping";
import { MappingType } from "../../res/enums";

const ReverseMapping = ({ history, location }) => {
  const [viewParameter, setViewParameter] = useState();
  const [save, setSave] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [regulationModel, setRegulationModel]=useState();
  const [generalDocumentModel, setGeneralDocumentModel]=useState();
  const initialMappingModel = {
    mappingType: "",
    id: ""
  }
  const [mappingModel, setMappingModel]=useState(initialMappingModel);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.regulation) {
        setRegulationModel(location.state.regulation);
        mappingModel.mappingType=MappingType.REGULATION;
        mappingModel.id=location.state.regulation.id;
        setMappingModel(mappingModel);
      }
      if (location.state.generalDocument) {
        setGeneralDocumentModel(location.state.generalDocument);
        mappingModel.mappingType=MappingType.GENERALDOCUMENT;
        mappingModel.id=location.state.generalDocument.id;
        setMappingModel(mappingModel);
      }
    }
  },[]); 
  
  const viewMapping = (
    selectedProductHierarchyId,
    flattenedProductHierarchy
  ) => {
    setViewParameter({
      productHierarchyId: selectedProductHierarchyId,
      productHierarchy: flattenedProductHierarchy
    });
  };

  const saveMapping = () => {
    setSave(Math.random());
  };

  const goBack = () => {
    if(mappingModel.mappingType === MappingType.GENERALDOCUMENT){
      history.push("/generaldocument");
    }
    if(mappingModel.mappingType === MappingType.REGULATION){
      history.push("/regulation");
    }
  };

  return(
    <Fragment>
      {generalDocumentModel?(
        <GeneralDocumentMapping
          generalDocumentModel = {generalDocumentModel}/>)
      :null}  
      {regulationModel?(
        <RegulationMapping
          regulationModel={regulationModel}/>)
      :null}
      <ProductHierarchySelector
        viewMapping={viewMapping}
        saveMapping={saveMapping}
        goBack={goBack}
        isPageLoading={isLoading}
      />
      <ReverseMappingComponent
        mappingModel={mappingModel}
        viewParameter={viewParameter}
        onSave={save}
        setIsLoading={setIsLoading}
      />
    </Fragment>
  );   
}

export default ReverseMapping;