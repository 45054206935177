import React, { useState, useEffect, Fragment } from "react";
import {
  Form,
  Card,
  Section,
  SelectField,
  InputGroup,
  TextInputField,
  TextAreaField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  RadioButtonField,
  ProgressSpinner
} from "luna-react";

import HttpStatus from "../../res/HttpStatus";

import * as utils from "../shared/Utils";
import { EventEmitter } from "../shared/EventEmitter";
import strings from "../../res/strings";
import { useSaveRegulation, useGetRegulations } from "../../api/regulationService";
import PopupComponent from "../popup/Popup";
import {useDropdownList} from "../shared/dropdown";

const RegulationForm = ({ history, location }) => {
  const [fields] = useState({
    standard: "Standard *",
    identifier: "Identifier *",
    description: "Description *",
    scope: "Scope *",
    note: "Notes",
    passRequirement: "Pass Requirements",
    proofOfCompliance: "Proof Of Compliance"
  });

  const initialForm = {
    id: null,
    standard: "",
    identifier: "",
    description: "",
    scope: "",
    note: "",
    passRequirement: "",
    proofOfCompliance: "",
    isActive: true,
    timestamp: Math.random(),
    mode: "Add"
  };

  const initialError = {
    standard: "",
    description: "",
    scope: "",
    identifier: ""
  };

  const dropdownList = useDropdownList();
  const [{ data, isLoading, isDataError }, setDataQuery] = useGetRegulations();
  const [searchQuery, setSearchQuery] = useState();
  const [documentIdentifierName, setDocumentIdentifierName] = useState([]);
  const [error, setError] = useState(initialError);
  const [form, setForm] = useState(initialForm);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [modalShow, setModalShow]=useState(false);
  const [showPopUp, setPopUpPage] =useState();
  
  const [
    { isSaved, isError, errorCode, errorMessage },
    setFormData
  ] = useSaveRegulation();
  const [documentId, setDocumentId]=useState();

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        
      }
      else{
        const max = Math.max.apply(null, data.map(item => item.id));
        const docId = data.find(x=>x.id === max && x.standard===form.standard);
        if(docId){
          setDocumentId(docId.identifier);
        }                
      }
    }
  }, [data]);
  useEffect(() => {
    if (isDataError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDataError]);

  useEffect(() => {
    setDataQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.regulation) {
        let regulation = location.state.regulation;
        regulation.mode = "Edit";
        setForm(regulation);
      }
    }
    EventEmitter.subscribe("dropdownValue",event=>setDocumentIdentifierName(event));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      if (errorCode === HttpStatus.STATUS_409_CONFLICT) {
        setError({
          ...error,
          standard: errorMessage
        });

        utils.scrollToError();
      } else {
        utils.toastError(strings.common.apiSaveErrorMessage);
      }
      setIsFormSubmitting(false);
    }
  }, [isError, errorCode]);

  useEffect(() => {
    setSearchQuery(
      `searchData=${form.standard}&isActive=true&ts=${Math.random()}`
    );
    if (isSaved && data) {
      setIsFormSubmitting(false);
      if(modalShow && documentId && form.mode==="Add"){
        setPopUpPage(<PopupComponent show={modalShow} handleClose = {closeModal} 
                      displayContent = {setPopupText(documentId, form.standard)}/>)
      }
      if(form.mode === "Edit"){
        utils.toastSuccess(strings.common.apiSaveSuccessMessage);
        handleBack();
      }
    }
  }, [isSaved, data]);
  
  const setPopupText = (documentId, standardName) => {
    let popupMessage = (<p>Name of the Document standard: {standardName}<br/>
                        Standard Identifier: {documentId}</p>)
    return popupMessage;
  }

  const closeModal = () => {
    setPopUpPage(null);
    utils.toastSuccess(strings.common.apiSaveSuccessMessage);
    handleBack();
  }
  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "radio"
          ? event.target.value === "Active"
            ? true
            : false
          : event.target.value
    });
  };

  const handleBlur = event => {
    setForm({ ...form, [event.target.name]: event.target.value.trim() });
  };

  const handleSubmit = event => {
    event.preventDefault();

    // Timestamp change if form isn't changed before submission, add/update button will stay disabled without this.
    setForm({ ...form, timestamp: Math.random() });

    if (handleFormValidation()) {
      setIsFormSubmitting(true);
      setFormData(form);
      setModalShow(true);
    } else {
      setTimeout(function() {
        utils.scrollToError();
      }, 100);
    }
  };

  const handleFormValidation = () => {
    let _error = initialError;
    let passed = true;

    //eslint-disable-next-line
    const isInValidFileName = /\||\\|\/|\#|\{|\}|\[|\]|\=|\+|\£|\$|\%|\^|\:/g;

    if (!form.standard) {
      _error.standard = "Standard is required";
      passed = false;
    }
    if (!form.identifier) {
      _error.identifier = "Identifier is required";
      passed = false;
    }
    if (!form.description) {
      _error.description = "Description is required";
      passed = false;
    }
    if (!form.scope) {
      _error.scope = "Scope is required";
      passed = false;
    }

    setError(_error);

    return passed;
  };

  const handleBack = () => {
    setForm(initialForm);
    setError(initialError);
    history.push("/regulation");
  };
  

  return (
    <Fragment>
      {showPopUp ? showPopUp : null}
    <Card className="card-padding">
      <Form className="o-form" id="regulation-form">
        <h2>{form.mode === "Add" ? "Add Requirement" : "Edit Requirement"}</h2>
        <Section>
          <TextInputField
            name="standard"
            label={fields.standard}
            value={form.standard}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={1000}
            error={error.standard}
            required={true}
          />
          
          {form.mode === "Add" ?(
            <SelectField as={InputGroup.Prepend}
              name="identifier"  
              label = {fields.identifier}           
              onBlur={handleBlur}
              onChange={handleChange}
              options = {documentIdentifierName}
              error={error.identifier}
              required={true}
            /> 
            ):
            (<TextInputField
              name="identifier"
              label = {fields.identifier}
              value={form.identifier}
              onBlur={handleBlur}
              disabled
            />)
          }
          <TextInputField
            name="description"
            label={fields.description}
            value={form.description}
            onBlur={handleBlur}
            maxLength={1000}
            onChange={handleChange}
            error={error.description}
            required={true}
          />
          <TextAreaField
            name="scope"
            label={fields.scope}
            value={form.scope}
            onBlur={handleBlur}
            onChange={handleChange}
            error={error.scope}
            required={true}
          />
          <TextAreaField
            name="note"
            label={fields.note}
            value={form.note}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
          />
          <TextAreaField
            name="passRequirement"
            label={fields.passRequirement}
            value={form.passRequirement}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
          />
          <TextAreaField
            name="proofOfCompliance"
            label={fields.proofOfCompliance}
            value={form.proofOfCompliance}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
          />
          {form.mode === "Edit" && (
            <RadioButtonField
              label={<span>Status</span>}
              name="isActive"
              listType="inline"
              value={form.isActive ? "Active" : "Inactive"}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" }
              ]}
              onChange={handleChange}
            />
          )}
          <ButtonGroupWrapper>
            <ButtonGroupPrimary>
              <button
                id="add-update-regulation"
                type="button"
                className="ln-c-button ln-c-button--primary"
                onClick={event => handleSubmit(event)}
                disabled={isFormSubmitting}
              >
                {form.mode === "Add" ? "Add" : "Update"}
              </button>
            </ButtonGroupPrimary>
            <ButtonGroupSecondary>
              <button
                id="cancel-form"
                type="button"
                className="ln-c-button ln-c-button--outlined"
                onClick={handleBack}
              >
                Back
              </button>
            </ButtonGroupSecondary>
          </ButtonGroupWrapper>
        </Section>
      </Form>
    </Card>
    </Fragment>
  );
};

export default RegulationForm;