export const DocumentType = {
  EXCEL: 0,
  PDF: 1
};

export const MappingType = {
  REGULATION: 0,
  GENERALDOCUMENT: 1
};

export const HierarchyType = {
  CATEGORY: "Category",
  SUBCATEGORY: "SubCategory",
  PRODUCTCLASS: "ProductClass",
  OPTION: "Option"
};
