import { api } from "./api";

const apiUrl = `${window.appConfig.rootUrl}/TestRequirements/`;

export function useGetTestRequirements() {
  const [{ data, isSaved, isSaving, isError }, setFormData] = api.useDataSave(
    apiUrl
  );

  return [{ data, isSaved, isSaving, isError }, setFormData];
}
