import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Header as LunaHeader } from "luna-react";
import { Sainsburys } from "luna-images";
import { hasRole, user } from "../../../api/auth";
import { redirectToLogout } from "@jsainsburyplc/colleague-auth";
import { withRouter } from "react-router-dom";

import "../../../index.scss";

function renderMenu(userRoles) {
  let menuItems = [];

  if (hasRole(userRoles, ["Operational Team", "Product Technologist"])) {
    menuItems.push(
      <NavLink
        id="testrequirement-menu-item"
        to="/testrequirement"
        activeClassName="is-active"
      >
        Test Requirements
      </NavLink>
    );
  }

  if (hasRole(userRoles, ["Operational Team"])) {
    menuItems.push(
      <NavLink id="mapping-menu-item" to="/mapping" activeClassName="is-active">
        Mapping
      </NavLink>
    );
    menuItems.push(
      <NavLink id="mapping-menu-item" to="/mappingReport" activeClassName="is-active">
        Mapping Reports
      </NavLink>
    );
    menuItems.push(
      <NavLink
        id="producthierarchy-menu-item"
        to="/producthierarchy"
        activeClassName="is-active"
      >
        Product Hierarchies
      </NavLink>
    );
    menuItems.push(
      <NavLink
        id="regulation-menu-item"
        to="/regulation"
        activeClassName="is-active"
      >
        Requirements
      </NavLink>
    );
    menuItems.push(
      <NavLink
        id="generaldocument-menu-item"
        to="/generaldocument"
        activeClassName="is-active"
      >
        General Documents
      </NavLink>
    );
    menuItems.push(
      <NavLink
        id="useradmin-menu-item"
        to="/useradmin"
        activeClassName="is-active"
      >
        User Admins
      </NavLink>
    );
  }

  return menuItems;
}

class Header extends Component {
  handleLogoutClick() {
    redirectToLogout();
  }

  render() {
    return (
      <LunaHeader
        logo={<div></div>}
        topBar={{
          logo: (
            <Sainsburys height="25" width="95" className="ln-u-display-block" />
          ),
          items: [
            <span className="remove-header-underline">
              {"Compliance Matrix: " + window.appConfig.version}
            </span>,
            <span className="remove-header-underline">
              {"Welcome " + user.name}
            </span>
          ],
          meta: (
            <a
              href="#logout"
              id="logout-button"
              onClick={this.handleLogoutClick}
            >
              Logout
            </a>
          )
        }}
        menuItems={renderMenu(user.roles)}
        style={{ position: "static" }}
      />
    );
  }
}

export default withRouter(Header);
