import React, { Fragment, useState } from "react";
import { Card } from "luna-react";
import PageTitleBar from "../shared/PageTitleBar";
import ActiveStatusFilter from "../shared/ActiveStatusFilter";
import ProductHierarchySelector from "./ProductHierarchySelector";
import MappingTable from "./MappingTable";
import MappingLegend from "./MappingLegend";

import "./index.scss";

const Mapping = () => {
  const [status, setStatus] = useState();
  const [viewParameter, setViewParameter] = useState();
  const [save, setSave] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = status => {
    setStatus(status);
  };

  const viewMapping = (
    selectedProductHierarchyId,
    flattenedProductHierarchy
  ) => {
    setViewParameter({
      productHierarchyId: selectedProductHierarchyId,
      productHierarchy: flattenedProductHierarchy,
      status: status
    });
  };

  const saveMapping = () => {
    setSave(Math.random());
  };

  return (
    <Fragment>
      <PageTitleBar title={"Mapping"} isLoading={isLoading} />

      <Card className="no-padding-bottom">
        <span className="inline-label">Status:</span>
        <ActiveStatusFilter
          activeStatus={true}
          inactiveStatus={false}
          onChange={status => handleStatusChange(status)}
        />
        <ProductHierarchySelector
          status={status}
          viewMapping={viewMapping}
          saveMapping={saveMapping}
          isPageLoading={isLoading}
        />
      </Card>

      <MappingTable
        viewParameter={viewParameter}
        onSave={save}
        setIsLoading={setIsLoading}
      />
      <MappingLegend></MappingLegend>
    </Fragment>
  );
};

export default Mapping;
