import React, { useState, useEffect } from "react";
import { FilledButton, Checkbox, Card } from "luna-react";
import { useGetTestRequirements } from "../../api/testRequirementService";
import { useGetExportRequirments } from "../../api/testRequirementExportService";
import HierarchyTableRegulation from "./HierarchyTableRegulation";
import HierarchyTableGeneralDocument from "./HierarchyTableGeneralDocument";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import jp from "jsonpath";
import { HierarchyType } from "../../res/enums";
import LoadingNotification from "../shared/LoadingNotification";
import { EventEmitter } from "../shared/EventEmitter";
import { DocumentType } from "../../res/enums";

const HierarchyTableContainer = () => {
  const [
    { data, isSaved, isSaving, isError },
    setFormData
  ] = useGetTestRequirements();
  const [
    { exportData, isDownloading, isDownloaded, isDownloadError, setExportData }
  ] = useGetExportRequirments();
  const [regulations, setRegulations] = useState([]);
  const [options, setOptions] = useState([]);
  const [generalDocuments, setGeneralDocuments] = useState([]);
  const [excludedRegulations, setExcludedRegulations] = useState();
  const [selectedHierarchyItems, setSelectedHierarchyItems] = useState();
  const [isNotesExcluded, setIsNotesExcluded] = useState(false);
  const [isViewReset, setIsViewReset] = useState(false);
  const [documentType, setDocumentType] = useState();

  useEffect(() => {
    EventEmitter.subscribe("selectedHierarchyItems", event =>
      setSelectedHierarchyItems(event)
    );
  }, []);

  useEffect(() => {
    EventEmitter.subscribe("resetGeneratedRequirements", event =>
      setIsViewReset(event)
    );
  }, []);

  useEffect(() => {
    if (isViewReset) {
      resetView();
    }
  }, [isViewReset]);

  useEffect(() => {
    if (selectedHierarchyItems) {
      setFormData(selectedHierarchyItems);
    }
  }, [selectedHierarchyItems]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isDownloadError) {
      utils.toastError(strings.common.apiDownloadErrorMessage);
    }
  }, [isDownloadError]);

  useEffect(() => {
    if (exportData) {
      fileDownload();
    }
  }, [exportData]);

  useEffect(() => {
    if (data) {
      if (
        data.regulations.length === 0 &&
        data.generalDocuments.length === 0 &&
        data.options.length === 0
      ) {
        utils.toastInfo(strings.common.noDataMessage);
        resetView();
      } else {
        setRegulations(data.regulations);
        setOptions(data.options);
        setGeneralDocuments(data.generalDocuments);
        setExcludedRegulations([]);
        setIsNotesExcluded(false);
        setTimeout(function() {
          utils.scrollToElement("test-document-table");
        }, 100);
      }
    }
  }, [data]);

  const fileDownload = () => {
    const blobType =
      documentType === DocumentType.EXCEL
        ? { type: "application/vnd.ms-excel" }
        : { type: "application/pdf" };

    const filename = `Compliance Test Requirements - ${new Date().toLocaleString()}`;
    const filenameType = documentType === DocumentType.EXCEL ? ".xlsx" : ".pdf";

    const file = new Blob([exportData], blobType);

    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    let a = document.createElement("a");

    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  const resetView = () => {
    setRegulations([]);
    setOptions([]);
    setGeneralDocuments([]);
    setExcludedRegulations([]);
    setIsNotesExcluded(false);
    setIsViewReset(false);
  };

  const handleExcludedRegulations = (
    productHierarchyId,
    childExcludedRegulations
  ) => {
    let excludedRegulationItems = new Object({});

    Object.assign(excludedRegulationItems, excludedRegulations);

    excludedRegulationItems[productHierarchyId] = {
      childExcludedRegulations
    };

    setExcludedRegulations(excludedRegulationItems);
  };

  const handleGenerateDocument = documentType => {
    const excludeNotes = { isNoteExcluded: isNotesExcluded };
    const documentFormat = { documentFormat: documentType };
    const excludedRegulationsItem = jp.query(
      excludedRegulations,
      `$..childExcludedRegulations[?(@)]`
    );

    const testRequirementsExportData = Object.assign(
      { testRequirements: selectedHierarchyItems },
      { excludedRegulations: excludedRegulationsItem },
      excludeNotes,
      documentFormat
    );

    setExportData(testRequirementsExportData);
    setDocumentType(documentType);
  };

  const handleExcludedNote = isChecked => {
    setIsNotesExcluded(isChecked);
  };

  return (
    <>
      {((isSaving && !isSaved) || (isDownloading && !isDownloaded)) && (
        <LoadingNotification notificationOpen={true} />
      )}
      <div id="test-document-table">
        {regulations && regulations.length > 0 && (
          <HierarchyTableRegulation
            productHierarchyId={0}
            callbackExcludedRegulations={handleExcludedRegulations}
            gridData={regulations}
            level={HierarchyType.PRODUCTCLASS}
            title="All Products - Compositions, Performance and Chemical"
          />
        )}
        {options &&
          options.length > 0 &&
          options.map(option => {
            return (
              <HierarchyTableRegulation
                key={option.id}
                productHierarchyId={option.id}
                callbackExcludedRegulations={handleExcludedRegulations}
                gridData={option.regulations}
                level={HierarchyType.OPTION}
                title={option.name}
              />
            );
          })}
        {generalDocuments && generalDocuments.length > 0 && (
          <HierarchyTableGeneralDocument gridData={generalDocuments} />
        )}
        {((regulations && regulations.length > 0) ||
          (options && options.length > 0) ||
          (generalDocuments && generalDocuments.length > 0)) && (
          <>
            <Card className="no-padding-bottom center-alignment">
              <Checkbox
                id="checkbox-exlude-notes"
                name="checkbox-exlude-notes"
                inline
                label="Exclude Notes"
                checked={isNotesExcluded}
                onChange={e => handleExcludedNote(e.target.checked)}
              />
            </Card>
            <Card className="center-alignment margin-bottom">
              <FilledButton
                id="generate-excel-document-button"
                className="ln-u-margin-right*2"
                onClick={() => handleGenerateDocument(DocumentType.EXCEL)}
              >
                Download Excel
              </FilledButton>
              <FilledButton
                id="generate-pdf-document-button"
                className="no-padding-top"
                onClick={() => handleGenerateDocument(DocumentType.PDF)}
              >
                Download PDF
              </FilledButton>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default HierarchyTableContainer;
