import React, { Fragment, useState, useEffect } from "react";
import PageTitleBar from "../shared/PageTitleBar";
import SearchBar from "../shared/SearchBar";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";

import { useGetGeneralDocuments } from "../../api/generalDocumentService";
import { useExportRequirementGeneralDocuments } from "../../api/requirementGeneralDocumentExportService";

import GeneralDocumentGrid from "./GeneralDocumentGrid";
import LoadingNotification from "../shared/LoadingNotification";


const GeneralDocument = props => {
  const [searchQuery, setSearchQuery] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [getDocumentQuery, setGetDocumentQuery] = useState();
  const [{ data, isFetching, isError }, setQuery] = useGetGeneralDocuments();
  const [
    { exportData, isDownloading, isDownloaded, isDownLoadError, setExportData }
  ] = useExportRequirementGeneralDocuments();

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
      else{
        const requirementGeneralDocumentExport = Object.assign(
          {generalDocumentData : data},
          {regulationData : null}
        );
        setExportData(requirementGeneralDocumentExport);
      }
    }
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    if (exportData) {
      fileDownload();
    }
  }, [exportData]);

  useEffect(()=>{
    if (isError) {
      console.error();
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isDownLoadError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDownLoadError]);
  
  useEffect(() => {
    setQuery(getDocumentQuery);
  }, [getDocumentQuery]);

  const handleSearch = (term, status) => {
    setSearchQuery(`searchData=${term}&isActive=${status}&ts=${Math.random()}`);
  };

  const handleTitleClick = () => {
    props.history.push("/generalDocument/0");
  };

  const handleDownload = () => {
    setGetDocumentQuery(`ts=${Math.random()}`);
  }

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };

    const filename = `General Document - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";

    const file = new Blob([exportData], blobType);

    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    let a = document.createElement("a");

    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  return (
    <Fragment>
      <PageTitleBar
        title={"Documents"}
        isLoading={isLoading}
        onClick={handleTitleClick}
      />

      {isDownloading && !isDownloaded && (
        <LoadingNotification notificationOpen={true} />
      )}

      <SearchBar
        placeholder="Enter text to search"
        onSearch={handleSearch}
        downloadButton = {true}
        handleDocumentDownload = {handleDownload}
      />
      <GeneralDocumentGrid
        history={props.history}
        searchQuery={searchQuery}
        setIsLoading={setIsLoading}
      />
    </Fragment>
  );
};

export default GeneralDocument;
