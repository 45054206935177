import React from 'react'
import { Card } from 'luna-react'

// Component for displaying the page not found error when an invalid URL is types in
const PageNotFound = () => (
  <Card>
    <h2>Page not found or user is not authorised to view it!</h2>
  </Card>
)

export default PageNotFound