import React, { Fragment, useState, useEffect } from "react";
import { SelectField, GridWrapper, GridItem, FilledButton } from "luna-react";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import { useGetProductHierarchies } from "../../api/productHierarchyService";
import { HierarchyType } from "../../res/enums";
import { EventEmitter } from "../shared/EventEmitter";
import jp from "jsonpath";

const ProductHierarchySelector = ({
  status,
  viewMapping,
  saveMapping,
  isPageLoading
}) => {
  const [{ data, isLoading, isError }, setQuery] = useGetProductHierarchies();
  const [productHierarchyData, setProductHierarchyData] = useState();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productClass, setProductClass] = useState([]);
  const initialHierarchy = {
    categoryId: null,
    subCategoryId: null,
    productClassId: null
  };
  const [selectedHierarchy, setSelectedHierarchy] = useState(initialHierarchy);
  const initialError = { categoryId: null, subCategoryId: null };
  const [error, setError] = useState(initialError);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (status !== undefined) {
      setQuery(`isActive=${status}&ts=${Math.random()}`);
    }
  }, [status]);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        viewMapping();
        setCategory([]);        
      } else {
        setProductHierarchyData(data);
        const localCategories = data.map(x => {
          return { value: x.id, label: x.isActive ? x.name : x.name + " *" };
        });
        setCategory(localCategories);
      }
      setSubCategory([]);
      setProductClass([]);
      setSelectedHierarchy(initialHierarchy);
    }
  }, [data]);

  const handleItemSelection = (hierarchyTypeValue, itemId) => {
    const selectedProductHierarchyId = itemId || null;

    switch (hierarchyTypeValue) {
      case HierarchyType.CATEGORY:
        setSelectedHierarchy({
          categoryId: selectedProductHierarchyId,
          subCategoryId: null,
          productClassId: null
        });
        if (selectedProductHierarchyId) {
          const subCategoryItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedProductHierarchyId})].subCategory`
          )[0];
          setSubCategory(
            subCategoryItems != null
              ? subCategoryItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *"
                  };
                })
              : []
          );
        } else {
          setSubCategory([]);
        }

        setProductClass([]);
        break;
      case HierarchyType.SUBCATEGORY:
        setSelectedHierarchy({
          ...selectedHierarchy,
          subCategoryId: selectedProductHierarchyId,
          productClassId: null
        });

        if (selectedProductHierarchyId) {
          const productClassItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedProductHierarchyId})].productClass`
          )[0];
          setProductClass(
            productClassItems != null
              ? productClassItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *",
                    hierarchyCode: x.hierarchyCode
                  };
                })
              : []
          );
        } else {
          setProductClass([]);
        }

        break;
      case HierarchyType.PRODUCTCLASS:
        setSelectedHierarchy({
          ...selectedHierarchy,
          productClassId: selectedProductHierarchyId
        });
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let passed = true;
    let _error = initialError;

    if (!selectedHierarchy.categoryId) {
      _error.categoryId = "Category is required";
      passed = false;
    }

    if (!selectedHierarchy.subCategoryId) {
      _error.subCategoryId = "Sub Category is required";
      passed = false;
    }

    setError(_error);

    return passed;
  };

  const handleViewMapping = () => {
    if (validateForm()) {
      let query = `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedHierarchy.subCategoryId})].productClass`;

      if (selectedHierarchy.productClassId) {
        query += `[?(@.id==${selectedHierarchy.productClassId})]`;
      }

      let productClassItems = jp.query(productHierarchyData, query)[0];

      if (productClassItems && !(productClassItems instanceof Array)) {
        productClassItems = [productClassItems];
      }

      const flattenedProductHierarchy = [];

      if (productClassItems) {
        productClassItems.forEach(pc => {
          flattenedProductHierarchy.push({
            id: pc.id,
            name: pc.name,
            isActive: pc.isActive,
            type: "productClass", 
            hierarchyCode: pc.hierarchyCode
          });
          pc.option.forEach(opt => {
            flattenedProductHierarchy.push({
              id: opt.id,
              name: opt.name,
              isActive: opt.isActive,
              type: "option",
              hierarchyCode: opt.hierarchyCode
            });
          });
        });
      }

      const categoryName = jp.query(
        productHierarchyData,
        `$[?(@.id==${selectedHierarchy.categoryId})].name`)[0];

      EventEmitter.dispatch("sendCategoryName", categoryName);

      viewMapping(
        selectedHierarchy.productClassId || selectedHierarchy.subCategoryId,
        flattenedProductHierarchy
      );
    }
  };

  const handleSaveMapping = () => {
    saveMapping();
  };

  return (
    <Fragment>
      <GridWrapper gutterSize="zero" id="product-hierarchy-selector">
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="category-select-field"
            name="category-select-field"
            label="Category *"
            value={selectedHierarchy.categoryId || ""}
            error={error.categoryId}
            options={category}
            onChange={event =>
              handleItemSelection(HierarchyType.CATEGORY, event.target.value)
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="sub-category-select-field"
            name="sub-category-select-field"
            label="Sub Category *"
            error={error.subCategoryId}
            options={subCategory}
            onChange={event =>
              handleItemSelection(HierarchyType.SUBCATEGORY, event.target.value)
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="product-class-select-field"
            name="product-class-select-field"
            label="Class"
            options={productClass}
            onChange={event =>
              handleItemSelection(
                HierarchyType.PRODUCTCLASS,
                event.target.value
              )
            }
          />
        </GridItem>
        <GridItem
          size="1/4"
          className="ln-u-soft button-container no-padding-left no-padding-right no-padding-bottom"
        >
          <FilledButton
            id="view-mapping-button"
            onClick={() => handleViewMapping()}
            disabled={isPageLoading}
          >
            View
          </FilledButton>
          <FilledButton
            id="save-mapping-button"
            onClick={() => handleSaveMapping()}
            disabled={isPageLoading}
          >
            Save
          </FilledButton>
        </GridItem>
      </GridWrapper>
    </Fragment>
  );
};

export default ProductHierarchySelector;
