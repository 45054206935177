import React, { Fragment, useState, useEffect } from "react";
import { Checkbox } from "luna-react";

const ActiveStatusFilter = ({ activeStatus, inactiveStatus, onChange }) => {
  const [form, setForm] = useState(null);

  useEffect(() => {
    setForm({ active: activeStatus, inactive: inactiveStatus });
  }, [activeStatus, inactiveStatus]);

  useEffect(() => {
    if (form) {
      const status = form.active === form.inactive ? "" : form.active;
      onChange(status);
    }
  }, [form]);

  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  return (
    form && (
      <Fragment>
        <Checkbox
          id="active-checkbox"
          name="active"
          label="Active"
          inline
          checked={form.active}
          onChange={event => handleChange(event)}
        />
        <Checkbox
          id="inactive-checkbox"
          name="inactive"
          label="Inactive"
          inline
          checked={form.inactive}
          onChange={event => handleChange(event)}
        />
      </Fragment>
    )
  );
};

export default ActiveStatusFilter;
