import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { Container } from "luna-react";
import { ToastContainer } from "react-toastify";

import Header from "../layout/header";
import UserAdmin from "../useradmin";
import ProductHierarchy from "../producthierarchy";
import PageNotFound from "../pagenotfound";
import { hasRole, user } from "../../api/auth";
import "react-toastify/dist/ReactToastify.css";
import Regulation from "../regulation";
import RegulationForm from "../regulation/RegulationForm";
import GeneralDocument from "../generalDocument";
import GeneralDocumentForm from "../generalDocument/GeneralDocumentForm";
import Mapping from "../mapping";
import TestRequirement from "../testRequirement";
import MappingReport from "../report/mappings";
import ReverseMapping from "../reversemapping";

const App = () => (
  <>
    <ToastContainer autoClose={3000} hideProgressBar />
    <BrowserRouter>
      <Header />
      <Container>
        <Switch>
          <Redirect exact from="/" exact to="/testrequirement" />
          {hasRole(user.roles, [
            "Operational Team",
            "Product Technologist"
          ]) && (
              <Route exact path="/testrequirement" component={TestRequirement} />
            )}
          {hasRole(user.roles, ["Operational Team"]) && (
            <>
              <Route
                exact
                path="/generaldocument"
                component={GeneralDocument}
              />
              <Route
                exact
                path="/generaldocument/:id"
                component={GeneralDocumentForm}
              />
              <Route
                exact
                path="/generaldocument/reversemapping/:id"
                component={ReverseMapping}
              />
              <Route exact path="/mapping" component={Mapping} />
              <Route
                exact
                path="/producthierarchy"
                component={ProductHierarchy}
              />
              <Route exact path="/regulation" component={Regulation} />
              <Route exact path="/regulation/:id" component={RegulationForm} />
              <Route exact path="/regulation/reversemapping/:id" component={ReverseMapping} />
              <Route exact path="/useradmin" component={UserAdmin} />
              <Route exact path="/mappingReport" component={MappingReport} />
            </>
          )}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Container>
    </BrowserRouter>
  </>
);

export default App;
