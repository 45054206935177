import { api } from "./api";

const apiUrl = `${window.appConfig.rootUrl}/TestRequirementExports/`;

export function useGetExportRequirments() {
  const [
    { data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ] = api.useDataDownload(apiUrl);

  return [
    {
      exportData: data,
      isDownloading,
      isDownloaded,
      isDownloadError,
      setExportData: setFormData
    }
  ];
}
