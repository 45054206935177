import { toast } from "react-toastify";
import "../../index.scss";

export const toastSuccess = message => {
  toast(message, {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "toaster-success"
  });
};

export const toastError = message => {
  toast(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "toaster-error"
  });
};

export const toastInfo = message => {
  toast(message, {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "toaster-info"
  });
};

export const scrollToElement = elementId => {
  const element = document.getElementById(elementId);
  if (element) {
    window.scrollTo({
      top: element.offsetTop,
      behavior: "smooth"
    });
    element.focus();
  }
};

export const scrollToError = () => {
  let firstError = document.querySelector(".ln-c-form-group.has-error");
  if (firstError) {
    window.scrollTo({
      top: firstError.offsetTop,
      behavior: "smooth"
    });
    if (firstError.querySelector("input") !== null) {
      firstError.querySelector("input").focus();
    } else if (firstError.querySelector("textarea") !== null) {
      firstError.querySelector("textarea").focus();
    }
  }
};
