import React, { useState, useEffect } from "react";
import { CheckboxField } from "luna-react";

const UserFormRoles = ({ data, onChange, fieldName, roleError, formRoles }) => {
  const [myRoles, setMyRoles] = useState([]);

  useEffect(() => {
    const handleRoles = () => {
      let checkRoles = [];
      if (data) {
        data.forEach(element => {
          checkRoles.push({
            label: element.name,
            value: element.id,
            checked: false
          });
        });
      }

      if (formRoles) {
        let selectedRoles = formRoles.split(",").map(function(item) {
          return item.trim();
        });

        checkRoles.forEach(function(element, i) {
          if (
            selectedRoles.includes(element.label) ||
            selectedRoles.includes(element.value)
          ) {
            checkRoles[i].checked = true;
          }
        });
      }

      setMyRoles(checkRoles);
    };

    handleRoles();
  }, [data, formRoles]);

  return (
    <>
      {myRoles && myRoles.length > 0 && (
        <CheckboxField
          id="user-roles"
          label="Roles *"
          name={fieldName}
          listType="inline"
          options={myRoles}
          onChange={onChange}
          error={roleError}
          required={true}
        />
      )}
    </>
  );
};

export default UserFormRoles;
