import React from "react";
import { ProgressIndicator, ProgressBar, Card, IconButton, FilledButton } from "luna-react";
import { Plus } from "luna-icons";
import ButtonGroup from "antd/lib/button/button-group";

const PageTitleBar = ({ title, isLoading, onClick, removeOnClick, resetButtonStatus }) => {
  return (
    <Card className="grid-header-container card-padding">
      <h2>{title}</h2>
      {isLoading && (
        <ProgressIndicator
          id="loading-bar"
          className="progress-indicator"
          loading
          preventFocus
        >
          <ProgressBar size="small" />
        </ProgressIndicator>
      )}
      <ButtonGroup className="button-group">
        {typeof removeOnClick === "function" && (
          <FilledButton
            id="remove-item"
            disabled = {resetButtonStatus}
            onClick={() => removeOnClick()}
          >
            Reset
          </FilledButton>
        )}
        {typeof onClick === "function" && (
          <IconButton
            id="add-item"
            variant="filled"
            label="Add item"
            hideLabel
            onClick={() => onClick()}
          >
            <Plus />
          </IconButton>
        )}
      </ButtonGroup>
    </Card>
  );
};

export default PageTitleBar;
