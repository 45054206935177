import React, { useState, useEffect } from "react";
import { Card, FormGroup } from "luna-react";
import { TreeSelect } from "antd";
import HierarchySelectorOption from "./HierarchySelectorOption";
import { HierarchyType } from "../../res/enums";
import { uuidGenerator } from "./utils";
import PropTypes from "prop-types";
import jp from "jsonpath";
import "antd/dist/antd.css";
import { EventEmitter } from "../shared/EventEmitter";

import "./index.scss";

const HierarchySelector = ({
  data,
  callbackHandleProductClass,
  hierarchySelectorCounter
}) => {
  const hierarchyDataValueMap = {};
  const [uuid] = useState(uuidGenerator());
  const [hierarchyDataMapped, setHierarchyDataMapped] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedHierarchyItem, setSelectedHierarchyItem] = useState();
  const initialHierarchyLevels = {
    category: "",
    subCategory: "",
    productClass: ""
  };
  const TreeNode = TreeSelect.TreeNode;
  const [hierarchyLevels, setHierarchyLevels] = useState(
    initialHierarchyLevels
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setHierarchyDataMapped(data);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedHierarchyItem) {
      setOptions([]);
      handleProductClass(NaN);
      setHierarchyLevels(initialHierarchyLevels);
    }
  }, [selectedHierarchyItem]);

  useEffect(() => {
    EventEmitter.subscribe("resetHierarchy", event =>
      removeHierarchy()
    );
  }, []);
  
  const removeHierarchy = () => {
    setOptions([]);
    handleProductClass(NaN);
    setHierarchyLevels(initialHierarchyLevels);
    setSelectedHierarchyItem();
    
  }

  const handleProductClass = id => {
    callbackHandleProductClass(uuid, parseInt(id), HierarchyType.PRODUCTCLASS);
  };

  const handleOptions = id => {
    let optionItems = jp.query(
      hierarchyDataMapped,
      `$..children[?(@.id==${id})]..option[*]`
    );

    setOptions(
      optionItems != null
        ? optionItems.map(x => {
            return {
              value: x.id,
              label: x.name,
              hierarchyCode: x.hierarchyCode,
              checked: false
            };
          })
        : []
    );
  };

  const loopHierarchyData = (list, parent) => {
    return (list || []).map(({ children, name }) => {
      const node = (hierarchyDataValueMap[name] = {
        parent,
        name
      });
      node.children = loopHierarchyData(children, node);
      return node;
    });
  };

  loopHierarchyData(data);

  const handleTreeChange = productClass => {
      setSelectedHierarchyItem(productClass);
      EventEmitter.dispatch("resetGeneratedRequirements", true);
  };

  const handleTreeSelect = (productClass, treeNodeInfo) => {
    const hierarchyId = treeNodeInfo.props.id;

    handleProductClass(hierarchyId);
    handleOptions(hierarchyId);

    let hierarchyList = getPath(productClass)
      .toString()
      .split(",");

    if (hierarchyList !== null) {
      setHierarchyLevels({
        category: hierarchyList[0],
        subCategory: hierarchyList[1],
        productClass: hierarchyList[2]
      });
    }

    setSelectedHierarchyItem(productClass);
  };

  const handleTreeFilter = (inputValue, treeNode) => {
    let value = treeNode.props["value"];

    if (value === undefined || value.constructor !== String) return false;

    if (value.toLowerCase().includes(inputValue.toLowerCase())) {
      return true;
    }
    return false;
  };

  const getPath = name => {
    const path = [];
    let current = hierarchyDataValueMap[name];
    while (current) {
      path.unshift(current.name);
      current = current.parent;
    }
    return path;
  };

  return (
    <Card
      className="card-spacing"
      id={`hierarchy-selector-${hierarchySelectorCounter}`}
    >
      <FormGroup name="hierarchy-selector-form" label="Hierarchy *">
        <div>
          <TreeSelect
            id="hierarchy-selector"
            name="hierarchy-selector"
            value={selectedHierarchyItem}
            showSearch
            className="tree-select"
            dropdownStyle={{ maxHeight: 400, maxWidth: 385, overflow: "auto" }}
            filterTreeNode={handleTreeFilter}
            placeholder={"Please select hierarchy"}
            searchPlaceholder={"Enter key search word here"}
            allowClear
            treeDefaultExpandAll
            treeNodeFilterProp="value"
            onChange={handleTreeChange}
            onSelect={handleTreeSelect}
          >
            {hierarchyDataMapped &&
              hierarchyDataMapped.length > 0 &&
              hierarchyDataMapped.map(category => (
                <TreeNode
                  key={category.id}
                  id={category.id}
                  title={category.name}
                  value={category.name}
                  selectable={false}
                >
                  {category.children != null &&
                    category.children.map(subCategory => (
                      <TreeNode
                        key={subCategory.id}
                        id={subCategory.id}
                        title={subCategory.name}
                        value={subCategory.name}
                        selectable={false}
                      >
                        {subCategory.children != null &&
                          subCategory.children.map(productClass => (
                            <TreeNode
                              key={productClass.id}
                              id={productClass.id}
                              title={<b>{productClass.name}&nbsp;({productClass.hierarchyCode})</b>}
                              value={productClass.name + productClass.hierarchyCode}
                              selectable={true}
                            />
                          ))}
                      </TreeNode>
                    ))}
                </TreeNode>
              ))}
          </TreeSelect>
          {hierarchyLevels.category &&
            hierarchyLevels.category.length > 0 &&
            hierarchyLevels.subCategory &&
            hierarchyLevels.subCategory.length > 0 &&
            hierarchyLevels.productClass &&
            hierarchyLevels.productClass.length > 0 && (
              <ol className="ln-c-breadcrumbs ln-o-inline-list display-inline">
                <li className="ln-c-breadcrumbs__item ln-o-inline-list__item">
                  <span className="ln-c-breadcrumbs__link">
                    {hierarchyLevels.category}
                  </span>
                </li>
                <li className="ln-c-breadcrumbs__item ln-o-inline-list__item">
                  <span className="ln-c-breadcrumbs__link">
                    {hierarchyLevels.subCategory}
                  </span>
                </li>
                <li className="ln-c-breadcrumbs__item ln-c-breadcrumbs__item--active ln-o-inline-list__item">
                  <span className="ln-c-breadcrumbs__link" aria-current="page">
                    {hierarchyLevels.productClass}
                  </span>
                </li>
              </ol>
            )}
        </div>
      </FormGroup>

      <FormGroup
        name="option-selector-form"
        label="Option"
        className="no-margin-bottom"
      >
        <HierarchySelectorOption
          name="option-selection"
          uuid={uuid}
          options={options}
          hierarchySelectorCounter={hierarchySelectorCounter}
        />
      </FormGroup>
    </Card>
  );
};

export default HierarchySelector;

HierarchySelector.propTypes = {
  data: PropTypes.array,
  callbackHandleProductClass: PropTypes.func.isRequired,
  hierarchySelectorCounter: PropTypes.number.isRequired
};
