import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Label,
  Card
} from "luna-react";

import "./index.scss";

const HierarchyTableGeneralDocument = ({ gridData }) => {
  const [localGeneralDocument, setLocalGeneralDocument] = useState();

  useEffect(() => {
    if (gridData) {
      setLocalGeneralDocument(gridData);
    }
  }, [gridData]);

  return (
    <Card className="padding">
      <Label htmlFor="">General Documents</Label>
      <TableContainer responsive id="selected-general-document-table">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell scope="colgroup">Standard</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Identifier</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Description</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Note</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Applies To</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {localGeneralDocument &&
            localGeneralDocument.length > 0 &&
            gridData.map(row => (
              <TableRow key={Math.random()}>
                <TableCell>{row.standard}</TableCell>
                <TableCell>{row.identifier}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.note}</TableCell>
                <TableCell>{row.appliesTo}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
    </Card>
  );
};

export default HierarchyTableGeneralDocument;

HierarchyTableGeneralDocument.propTypes = {
  gridData: PropTypes.array.isRequired
};
