import React, { useEffect, useState } from "react";
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from "luna-react";
import { Card } from "antd";

const RegulationMapping = (regulationModel) =>{
  const [localRegulation, setLocalRegulation]=useState();

  useEffect(()=>{
    if(regulationModel){
        setLocalRegulation(regulationModel.regulationModel);
    }
  },[regulationModel])
  return(
    <Card>
      <TableContainer responsive>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell scope="colgroup">Standard</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Identifier</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Description</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Scope</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Note</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Pass Requirement</TableHeaderCell>
            <TableHeaderCell scope="colgroup">
              Proof of Compliance
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {localRegulation &&
           (
              <TableRow key={Math.random()} className="regulation-mapping-cell">
                <TableCell>{localRegulation.standard}</TableCell>
                <TableCell>{localRegulation.identifier}</TableCell>
                <TableCell>{localRegulation.description}</TableCell>
                <TableCell>{localRegulation.scope}</TableCell>
                <TableCell>{localRegulation.note}</TableCell>
                <TableCell>{localRegulation.passRequirement}</TableCell>
                <TableCell>{localRegulation.proofOfCompliance}</TableCell>
              </TableRow>
              )
          }
        </TableBody>
      </TableContainer>
    </Card>
  );
}
export default RegulationMapping;