import React, { useState } from "react";
import PageTitleBar from "../shared/PageTitleBar";
import HierarchySelectorContainer from "./HierarchySelectorContainer";
import HierarchyTableContainer from "./HierarchyTableContainer";

const TestRequirement = () => {
  const [isAddCategoryClicked, setIsAddCategoryClicked] = useState(false);
  const [isTestRequirementCleared, setIsTestRequirementCleared] = useState(false);
  const [resetButtonDisabled, setResetButtonDisabled]=useState(true);
  const handleTitleClick = () => {
    setIsAddCategoryClicked(true);
  };
  const resetPage = () => {
    setIsTestRequirementCleared(true);
  }

  return (
    <>
      <PageTitleBar
        title={"Test Requirements"}
        isLoading={false}
        onClick={handleTitleClick}
        removeOnClick={resetPage}
        resetButtonStatus = {resetButtonDisabled}
      />
      <HierarchySelectorContainer
        isAddCategoryClicked={isAddCategoryClicked}
        setIsAddCategoryClicked={setIsAddCategoryClicked}
        isTestRequirementCleared={isTestRequirementCleared}
        setIsTestRequirementCleared={setIsTestRequirementCleared}
        resetButtonDisabled={resetButtonDisabled}
        setResetButtonDisabled = {setResetButtonDisabled}
      />
      <HierarchyTableContainer />
    </>
  );
};

export default TestRequirement;
