import React, { useState, useEffect } from "react";
import { SelectField, GridWrapper, GridItem, FilledButton } from "luna-react";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import { useGetProductHierarchies } from "../../api/productHierarchyService";
import { HierarchyType } from "../../res/enums";
import jp from "jsonpath";
import { Card } from "antd";

const ProductHierarchySelector = ({
  viewMapping,
  saveMapping,
  goBack,
  isPageLoading
}) => {
  const [{ data, isLoading, isError }, setQuery] = useGetProductHierarchies();
  const [productHierarchyData, setProductHierarchyData] = useState();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productClass, setProductClass] = useState([]);
  const initialHierarchy = {
    categoryId: null,
    subCategoryId: null,
    productClassId: null
  };
  const [selectedHierarchy, setSelectedHierarchy] = useState(initialHierarchy);
  const initialError = { categoryId: null, subCategoryId: null };
  const [error, setError] = useState(initialError);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    setQuery(`ts=${Math.random()}`);
  }, []);

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      } else {
        setProductHierarchyData(data);
        const localCategories = data.map(x => {
          return { value: x.id, label: x.isActive ? x.name : x.name + " *" };
        });
        setCategory(localCategories);
        setSubCategory([]);
        setProductClass([]);
        setSelectedHierarchy(initialHierarchy);
      }
    }
  }, [data]);

  const handleItemSelection = (hierarchyTypeValue, itemId) => {
    const selectedProductHierarchyId = itemId || null;

    switch (hierarchyTypeValue) {
      case HierarchyType.CATEGORY:
        setSelectedHierarchy({
          categoryId: selectedProductHierarchyId,
          subCategoryId: null,
          productClassId: null
        });
        if (selectedProductHierarchyId) {
          const subCategoryItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedProductHierarchyId})].subCategory`
          )[0];
          setSubCategory(
            subCategoryItems != null
              ? subCategoryItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *"
                  };
                })
              : []
          );
        } else {
          setSubCategory([]);
        }

        setProductClass([]);
        break;
      case HierarchyType.SUBCATEGORY:
        setSelectedHierarchy({
          ...selectedHierarchy,
          subCategoryId: selectedProductHierarchyId,
          productClassId: null
        });

        if (selectedProductHierarchyId) {
          const productClassItems = jp.query(
            productHierarchyData,
            `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedProductHierarchyId})].productClass`
          )[0];
          setProductClass(
            productClassItems != null
              ? productClassItems.map(x => {
                  return {
                    value: x.id,
                    label: x.isActive ? x.name : x.name + " *",
                    hierarchyCode: x.hierarchyCode
                  };
                })
              : []
          );
        } else {
          setProductClass([]);
        }

        break;
      case HierarchyType.PRODUCTCLASS:
        setSelectedHierarchy({
          ...selectedHierarchy,
          productClassId: selectedProductHierarchyId
        });
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let passed = true;
    let _error = initialError;

    if (!selectedHierarchy.categoryId) {
      _error.categoryId = "Category is required";
      passed = false;
    }
    setError(_error);

    return passed;
  };

  const handleViewMapping = () => {
    if (validateForm()) {
      let query = `$[?(@.id==${selectedHierarchy.categoryId})].subCategory`;
      let subCategoryItems = jp.query(productHierarchyData, query)[0];
      
      if (subCategoryItems && !(subCategoryItems instanceof Array)) {
        subCategoryItems = [subCategoryItems];
      }
      let productClassItems = null;
      if(selectedHierarchy.subCategoryId){
        query += `[?(@.id==${selectedHierarchy.subCategoryId})].productClass`;
        productClassItems = jp.query(productHierarchyData, query)[0];
      }

      if (productClassItems && !(productClassItems instanceof Array)) {
        productClassItems = [productClassItems];
      }

      let category = productHierarchyData.find(x=>x.id==selectedHierarchy.categoryId);
      let flattenedProductHierarchy = [];

      if(!productClassItems && subCategoryItems){
        subCategoryItems.forEach(sc=>{
          sc.productClass.forEach(pc=>{
            flattenedProductHierarchy.push({
              id: pc.id,
              categoryName: category.name,
              subcategoryName: sc.name,
              productClassName: pc.name,
              productHierachyCode: pc.hierarchyCode,
              optionName: null,
              optionHierarchyCode: null
            });
            pc.option.forEach(op=>{
              flattenedProductHierarchy.push({
                id: op.id,
                categoryName: category.name,
                subcategoryName: sc.name,
                productClassName: pc.name,
                productHierachyCode: pc.hierarchyCode,
                optionName: op.name,
                optionHierarchyCode: op.hierarchyCode
              });
            });
          });
        });
      }

      if (productClassItems) {
        let subCategoryName = category.subCategory.find(x=>x.id==selectedHierarchy.subCategoryId);

        productClassItems.forEach(pc => {
          flattenedProductHierarchy.push({
            id: pc.id,
            categoryName: category.name,
            subcategoryName: subCategoryName.name,
            productClassName: pc.name,
            productHierachyCode: pc.hierarchyCode,
            optionName: null,
            optionHierarchyCode: null,
            isActive: pc.isActive
          });
          pc.option.forEach(op => {
            flattenedProductHierarchy.push({
              id: op.id,
              categoryName: category.name,
              subcategoryName: subCategoryName.name,
              productClassName: pc.name,
              productHierachyCode: pc.hierarchyCode,
              optionName: op.name,
              optionHierarchyCode: op.hierarchyCode,
              isActive: op.isActive
            });
          });
        });
      }

      viewMapping(
        selectedHierarchy.productClassId || selectedHierarchy.subCategoryId,
        flattenedProductHierarchy
      );
    }
  };

  const handleSaveMapping = () => {
    saveMapping();
  };

  const handleBack = () => {
    goBack();
  }

  return (
    <Card>
      <GridWrapper gutterSize="zero" id="reverse-mapping-hierarchy-selector">
        <GridItem
          size="1/3"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="category-select-field"
            name="category-select-field"
            label="Category *"
            value={selectedHierarchy.categoryId || ""}
            error={error.categoryId}
            options={category}
            onChange={event =>
              handleItemSelection(HierarchyType.CATEGORY, event.target.value)
            }
          />
        </GridItem>
        <GridItem
          size="1/3"
          className="ln-u-soft no-padding-left no-padding-bottom"
        >
          <SelectField
            id="sub-category-select-field"
            name="sub-category-select-field"
            label="Sub Category"
            options={subCategory}
            onChange={event =>
              handleItemSelection(HierarchyType.SUBCATEGORY, event.target.value)
            }
          />
        </GridItem>
        
        <GridItem
          size="1/3"
          className="ln-u-soft button-container no-padding-left no-padding-right no-padding-bottom"
        >
          <FilledButton
            id="view-mapping-button"
            onClick={() => handleViewMapping()}
            disabled={isPageLoading}
          >
            View
          </FilledButton>
          <FilledButton
            id="save-mapping-button"
            onClick={() => handleSaveMapping()}
            disabled={isPageLoading}
          >
            Save
          </FilledButton>
          <FilledButton
           id="back-button"
           onClick={() => handleBack()}
           disabled={isPageLoading}
           >
            Back
          </FilledButton>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default ProductHierarchySelector;
