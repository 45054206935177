import { api } from "../api/api";

const apiUrl = `${window.appConfig.rootUrl}/RequirementGeneralDocumentExport`;

export function useExportRequirementGeneralDocuments() {
    const [
      { data, isDownloading, isDownloaded, isDownloadError },
      setFormData
    ] = api.useDataDownload(apiUrl);
  
    return [
      {
        exportData: data,
        isDownloading,
        isDownloaded,
        isDownloadError,
        setExportData: setFormData
      }
    ];
  }