import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FilledButton, Card } from "luna-react";
import { useGetProductHierarchies } from "../../api/productHierarchyService";
import { HierarchyType } from "../../res/enums";
import { EventEmitter } from "../shared/EventEmitter";
import { uuidGenerator } from "./utils";
import HierarchySelector from "./HierarchySelector";
import LoadingNotification from "../shared/LoadingNotification";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import jp from "jsonpath";

import "./index.scss";

const HierarchySelectorContainer = ({
  isAddCategoryClicked,
  setIsAddCategoryClicked,
  isTestRequirementCleared,
  setIsTestRequirementCleared,
  resetButtonDisabled,
  setResetButtonDisabled
}) => {
  const [hierarchyData, setHierarchyData] = useState();
  const [selectedProductClass, setSelectedHierarchy] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [hierarchySelector, setHierarchySelector] = useState([]);
  const [hierarchySelectorCounter, setHierarchySelectorCounter] = useState(0);
  const [{ data, isLoading, isError }, setQuery] = useGetProductHierarchies();

  useEffect(() => {
    setQuery(`isActive=true&ts=${Math.random()}`);
  }, []);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        utils.toastInfo(strings.common.noDataMessage);
      } else {
        handleHierarchyKeyRename(data);
        setHierarchySelectorCounter(
          hierarchySelectorCounter => hierarchySelectorCounter + 1
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    EventEmitter.subscribe("selectedOptions", event =>
      handleSelectedOptions(event.uuid, event.selectedOptions)
    );
  }, []);

  useEffect(() => {
    if (isAddCategoryClicked) {
      setHierarchySelectorCounter(
        hierarchySelectorCounter => hierarchySelectorCounter + 1
        );

      setHierarchySelector([
        ...hierarchySelector,
        <HierarchySelector
          key={uuidGenerator()}
          hierarchySelectorCounter={hierarchySelectorCounter}
          data={hierarchyData}
          callbackHandleProductClass={handleSelectedProductClass}
        />
      ]);
      setResetButtonDisabled(false);
      setIsAddCategoryClicked(false);
    }
  }, [isAddCategoryClicked]);

  useEffect(()=>{
    if(isTestRequirementCleared){
      handleSelectedProductClass(NaN);
      setHierarchySelector([]);
      setHierarchySelectorCounter(
        hierarchySelectorCounter => hierarchySelectorCounter - (hierarchySelectorCounter-1)
      );
      EventEmitter.dispatch("resetGeneratedRequirements", true);
      EventEmitter.dispatch("resetHierarchy", true);
      setResetButtonDisabled(true);
    }
    setIsTestRequirementCleared(false);
  },[isTestRequirementCleared])

  const handleSelectedProductClass = (uuid, productClassId, enumValue) => {
    resetSelectedOptions(uuid);

    const clonedSelectedProductClass = Object.create(selectedProductClass);

    setSelectedHierarchy(prevstate => {
      let productClassDict = new Object({});

      Object.assign(productClassDict, clonedSelectedProductClass, prevstate);
      
      if (isNaN(productClassId)) {
        delete productClassDict[uuid];
      } else {
        setResetButtonDisabled(false);
        productClassDict[uuid] = {
          productClassId: productClassId,
          Level: enumValue
        };
      }

      return productClassDict;
    });
  };

  const resetSelectedOptions = uuid => {
    const clonedSelectedOption = Object.create(selectedOption);

    setSelectedOption(prevOption => {
      let optionDict = new Object({});

      Object.assign(optionDict, clonedSelectedOption, prevOption);

      delete optionDict[uuid];

      return optionDict;
    });
  };

  const handleSelectedOptions = (uuid, selectedOptionId) => {
    EventEmitter.dispatch("resetGeneratedRequirements", true);

    const clonedSelectedOption = Object.create(selectedOption);

    setSelectedOption(prevOption => {
      let optionDict = new Object({});

      Object.assign(optionDict, clonedSelectedOption, prevOption);

      optionDict[uuid] = {
        productClassId: selectedOptionId,
        Level: HierarchyType.OPTION
      };

      return optionDict;
    });
  };

  const handleRequirements = () => {
    if (handleValidateForm()) {
      const productClassHierarchyItems = gatherMappedProductClasses();

      const optionHierarchyItems = gatherMappedOptions();

      const combinedHierarchyItems =
        optionHierarchyItems !== undefined
          ? productClassHierarchyItems.concat(optionHierarchyItems)
          : productClassHierarchyItems;

      EventEmitter.dispatch("selectedHierarchyItems", combinedHierarchyItems);
    } else {
      EventEmitter.dispatch("resetGeneratedRequirements", true);
    }
  };

  const handleHierarchyKeyRename = hierarchyData => {
    hierarchyData.forEach(category => {
      category.subCategory != null &&
        delete Object.assign(category, { children: category.subCategory })
          .subCategory;
      category.children != null &&
        category.children.forEach(subCategory => {
          subCategory.productClass != null &&
            delete Object.assign(subCategory, {
              children: subCategory.productClass
            }).productClass;
        });
    });

    setHierarchyData(hierarchyData);
  };

  const gatherMappedProductClasses = () => {
    let productClassHierarchyItems;

    if (selectedProductClass) {
      productClassHierarchyItems = jp.query(selectedProductClass, `$[?(@)]`);
      productClassHierarchyItems = productClassHierarchyItems.map(pc => {
        return {
          productHierarchyId: pc.productClassId,
          productHierarchyLevel: pc.Level
        };
      });
    }

    return productClassHierarchyItems;
  };

  const gatherMappedOptions = () => {
    let optionHierarchyItems;

    if (selectedOption) {
      optionHierarchyItems = jp.query(selectedOption, `$..productClassId.*`);
      optionHierarchyItems = optionHierarchyItems.map(id => {
        return {
          productHierarchyId: id,
          productHierarchyLevel: HierarchyType.OPTION
        };
      });
    }

    return optionHierarchyItems;
  };

  const handleValidateForm = () => {
    let passed = true;
    let productClassIds = "";

    if (selectedProductClass) {
      productClassIds = jp.query(selectedProductClass, `$..productClassId`);
    }

    if (productClassIds === undefined || productClassIds.length === 0) {
      utils.toastInfo(strings.common.noProductClassSelected);
      passed = false;
    }

    return passed;
  };

  return (
    <>
      {isLoading && <LoadingNotification notificationOpen={true} />}
      {data && (
        <HierarchySelector
          key={1}
          hierarchySelectorCounter={hierarchySelectorCounter}
          data={hierarchyData}
          callbackHandleProductClass={handleSelectedProductClass}
          isTestRequirementCleared={isTestRequirementCleared}
        />
      )}
      {hierarchySelector}
      <Card className="card-spacing center-alignment">
        <FilledButton
          id="generate-requirements-button"
          onClick={() => handleRequirements()}
        >
          Generate Requirements
        </FilledButton>
      </Card>
    </>
  );
};

export default HierarchySelectorContainer;

HierarchySelectorContainer.propTypes = {
  isAddCategoryClicked: PropTypes.bool.isRequired,
  setIsAddCategoryClicked: PropTypes.func.isRequired,
  isTestRequirementCleared: PropTypes.bool.isRequired,
  setIsTestRequirementCleared: PropTypes.func.isRequired
};
