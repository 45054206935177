import React, { Fragment, useState } from "react";
import { Card } from "luna-react";
import PageTitleBar from "../../shared/PageTitleBar";
import ProductHierarchySelector from "./ProductHierarchySelector";
import ReversedMappingReport from "./ReversedMappingReport";

const Mapping = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <Fragment>
      <PageTitleBar title={"Mapping from Product Hierarchy to Documents"} isLoading={isLoading} />

      <Card className="no-padding-bottom">
        <ProductHierarchySelector isPageLoading={isLoading} />
      </Card>

      <PageTitleBar title={"Mapping from Documents to Product Hierarchy"} isLoading={isLoading} />

      <Card className="document-download">
        <ReversedMappingReport/>
      </Card>
    </Fragment>
  );
};

export default Mapping;
