import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Section,
  TextInputField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  RadioButtonField
} from "luna-react";

import { concatenatedRoles, covertRoleNamesToId } from "./utils";

import { useGetRoles, useSaveUser } from "../../api/userAdminService";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import HttpStatus from "../../res/HttpStatus";

import UserFormRoles from "./UserFormRoles";

const UserForm = ({
  user,
  setIsFormVisible,
  setUser,
  onEditToggle,
  notifyFormSubmissionDone
}) => {
  const [fields] = useState({
    id: "id",
    email: "email",
    name: "name",
    roles: "roles"
  });

  const initialForm = {
    id: null,
    name: "",
    email: "",
    roles: "",
    isActive: true,
    mode: "Add"
  };

  const initialError = {
    name: "",
    email: "",
    roles: ""
  };

  const [error, setError] = useState({
    name: "",
    email: "",
    roles: ""
  });

  const [form, setForm] = useState({
    id: null,
    name: "",
    email: "",
    roles: "",
    isActive: true,
    timestamp: Math.random(),
    mode: "Add"
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState(Math.random());

  const [{ data, isLoading }, setQuery] = useGetRoles();
  const [
    { isSaved, isError, errorCode, errorMessage },
    setUserData
  ] = useSaveUser();

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (user && user.roles && data) {
      setError(initialError);

      let concatenatedRoles = covertRoleNamesToId(user.roles, data);

      setForm({
        ...user,
        roles: concatenatedRoles,
        mode: "Edit",
        timestamp: Math.random()
      });
    } else {
      setForm(initialForm);
    }

    utils.scrollToElement("user-form");
  }, [user, data]);

  useEffect(() => {
    if (isError) {
      if (errorCode === HttpStatus.STATUS_409_CONFLICT) {
        setError({
          ...error,
          email: errorMessage
        });
      } else {
        utils.toastError(strings.common.apiSaveErrorMessage);
      }
      setIsFormSubmitting(false);
    }
  }, [isError, errorCode]);

  useEffect(() => {
    if (isSaved) {
      setIsFormSubmitting(false);
      utils.toastSuccess(strings.common.apiSaveSuccessMessage);
      handleCancel();
      notifyFormSubmissionDone();
    }
  }, [isSaved]);

  const handleSubmit = event => {
    event.preventDefault();
    setForm({ ...form, timestamp: Math.random() });

    if (handleFormValidation()) {
      setIsFormSubmitting(true);
      setUserData(form);
    }
  };

  const handleChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]:
        target.type === "radio"
          ? target.value === "Active"
            ? true
            : false
          : target.value,
      roles: concatenatedRoles(target)
    });
  };

  const handleFormValidation = () => {
    const _errors = {};

    //eslint-disable-next-line
    const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //eslint-disable-next-line
    const inputValidation = /^[a-zA-Z0-9.'\-_\s]{1,100}$/;

    if (!form.name) {
      _errors.name = "Name is required";
    } else if (!inputValidation.test(form.name)) {
      _errors.name = "Name is not valid";
    }
    if (!form.email) {
      _errors.email = "Email is required";
    } else if (!emailValidation.test(form.email)) {
      _errors.email = "Email is not valid";
    }
    if (!form.roles) _errors.roles = "A role is required";

    setError(_errors);

    return Object.keys(_errors).length === 0;
  };

  const handleCancel = () => {
    setForm(initialForm);
    setUser();
    setIsFormVisible(false);
    onEditToggle(null);
  };

  const handleBlur = event => {
    setForm({ ...form, [event.target.name]: event.target.value.trim() });
  };

  return (
    <Card className="card-padding">
      <Form className="o-form" id="user-form">
        <h2>{form.mode === "Add" ? "Add User" : "Edit User"}</h2>
        <Section>
          <TextInputField
            id="name-input"
            name={fields.name}
            label="Name *"
            maxLength={60}
            value={form.name}
            onChange={handleChange}
            onPaste={handleChange}
            onBlur={handleBlur}
            error={error.name}
            required={true}
          />
          <TextInputField
            id="email-input"
            name={fields.email}
            label="Email *"
            type="email"
            maxLength={128}
            value={form.email}
            onBlur={handleBlur}
            onPaste={handleChange}
            onChange={handleChange}
            error={error.email}
            required={true}
          />
          <UserFormRoles
            data={data}
            onChange={handleChange}
            fieldName={fields.roles}
            roleError={error.roles}
            formRoles={form.roles}
          />
          {form.mode === "Edit" && (
            <RadioButtonField
              id="user-status"
              label={<span>Status</span>}
              info={"Inactive user can no longer access the system"}
              name="isActive"
              listType="inline"
              value={form.isActive ? "Active" : "Inactive"}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" }
              ]}
              onChange={handleChange}
            />
          )}
          <ButtonGroupWrapper>
            <ButtonGroupPrimary>
              <button
                id="add-update-user"
                type="button"
                className="ln-c-button ln-c-button--primary"
                onClick={event => handleSubmit(event)}
                disabled={isFormSubmitting}
              >
                {form.mode === "Add" ? "Add" : "Update"}
              </button>
            </ButtonGroupPrimary>
            {form.mode && (
              <ButtonGroupSecondary>
                <button
                  id="cancel-form"
                  type="button"
                  className="ln-c-button ln-c-button--outlined"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </ButtonGroupSecondary>
            )}
          </ButtonGroupWrapper>
        </Section>
      </Form>
    </Card>
  );
};

export default UserForm;
