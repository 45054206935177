import React, { Fragment, useState, useEffect } from "react";
import { Card } from "luna-react";
import MappingCheckbox from "./MappingCheckBox";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import { useGetMappings, useSaveMappings } from "../../api/mappingService";
import { EventEmitter } from "../shared/EventEmitter";
import { MappingType } from "../../res/enums";

const MappingTable = ({ viewParameter, onSave, setIsLoading }) => {
  const [{ data, isLoading, isError }, setQuery] = useGetMappings();
  const [{ isSaved, isSaveError }, setFormData] = useSaveMappings();
  const [regulationDocuments, setRegulationDocuments] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [categoryName, sendCategoryName] = useState();
  const [productHierarchyData, setProducHierarchyData] = useState([]);

  const populateGrid = (regulationDocuments, mappings) => {
    const gridItems = [];
    const productHierarchyItem = [];
    const regulationDocumentsCount = regulationDocuments.length;
    
    viewParameter.productHierarchy.map((hier, row) =>{

      productHierarchyItem.push({
        id: hier.id,
        index: row,
        name: hier.name,
        hierarchyCode: hier.hierarchyCode,
        type: hier.type,
        isActive: hier.isActive
      })

      regulationDocuments.map((reg, col) => {
        gridItems.push({
          id: null,
          index: row * regulationDocumentsCount + col,
          productHierarchyId: hier.id,
          itemId: reg.id,
          mappingType: reg.mappingType,
          checked: false,
          isActive: reg.isActive
        });
      })
      
    });

    mappings.map(m => {
      const item = gridItems.find(
        x =>
          x.productHierarchyId === m.productHierarchyId &&
          x.itemId === m.itemId &&
          x.mappingType === m.mappingType
      );

      if (item) {
        item.id = m.id;
        item.checked = m.isActive;
      }
    });
    setProducHierarchyData(productHierarchyItem);
    setGridData(gridItems);
  };

  //get the mapped general document and requirement front
  const filterRegulationDocument = (regulationDocuments, mappings) => {
    const regulationMapped = [];
    const regulationUnmapped = [];
    regulationDocuments.map(reg => {
      const item = mappings.find(
        x =>
          x.itemId === reg.id &&
          x.mappingType === reg.mappingType &&
          x.isActive === true
      );
      if(item){
        regulationMapped.push({
          id: reg.id,
          mappingType: reg.mappingType,
          name: reg.name,
          isActive: reg.isActive,
          description: reg.description,
          identifier: reg.identifier,
          checked: true
        })
      }
      else{
        regulationUnmapped.push({
          id: reg.id,
          mappingType: reg.mappingType,
          name: reg.name,
          isActive: reg.isActive,
          description: reg.description,
          identifier: reg.identifier,
          checked: false
        })
      }
    });
    for(let regDoc of regulationUnmapped){
      regulationMapped.push(regDoc);
    }
    return regulationMapped;
  }
 
  useEffect(() => {
    EventEmitter.subscribe("sendCategoryName", event =>
    sendCategoryName(event)
    );
  }, []);
  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
      setIsLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (isSaveError) {
      utils.toastError(strings.common.apiSaveErrorMessage);
      setIsLoading(false);
    }
  }, [isSaveError]);

  useEffect(() => {
    if (viewParameter) {
      if (!viewParameter.productHierarchy) {
        utils.toastInfo(strings.common.noDataMessage);
        setGridData([]);
      } else {
        setQuery(
          `isActive=${viewParameter.status}&productHierarchyId=${
            viewParameter.productHierarchyId
          }&ts=${Math.random()}`
        );
      }
    }
  }, [viewParameter]);

  useEffect(() => {
    if (data) {
      if (data.regulationDocuments.length === 0) {
        utils.toastInfo(strings.common.noDataMessage);
        setGridData([]);
      } else {
        const regulationDocumentFiltered = filterRegulationDocument(data.regulationDocuments, data.mappings);
        setRegulationDocuments(regulationDocumentFiltered);
        populateGrid(regulationDocumentFiltered, data.mappings);
      }
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSaved) {
      setQuery(
        `isActive=${viewParameter.status}&productHierarchyId=${
          viewParameter.productHierarchyId
        }&ts=${Math.random()}`
      );

      utils.toastSuccess(strings.common.apiSaveSuccessMessage);
    }
  }, [isSaved]);

  useEffect(() => {
    if (onSave) {
      const savedData = gridData
        .filter(x => x.isDirty)
        .map(x => {
          return {
            id: x.id || null,
            mappingType: x.mappingType,
            productHierarchyId: x.productHierarchyId,
            itemId: x.itemId,
            isActive: !x.isActive || x.checked
          };
        });

      if (savedData.length === 0) {
        utils.toastInfo(strings.common.noUpdatesToSave);
      } else {
        setFormData(savedData);
        setIsLoading(true);
      }
    }
  }, [onSave]);

  const handleMappingChange = index => {
    const gridItems = [...gridData];
    gridItems[index].checked = !gridItems[index].checked;

    if (gridItems[index].isDirty) {
      gridItems[index].isDirty = false;
    } else {
      gridItems[index].isDirty = true;
    }

    setGridData(gridItems);
  };

  const getEllipsis = (name, charLimit) => {
    
    if (name.length > charLimit) {
      return `${name.substr(0,charLimit)}...`;
    } else {
      return name;
    }
  };
  
  return (
    <Fragment>
      {productHierarchyData && productHierarchyData.length > 0 && gridData &&gridData.length > 0 && (
        <Card className="card-padding mapping-table" id="mapping-table">
          <div className="mapping">
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th className="first-column grid-top-header"></th>
                  <th
                    colSpan={
                      regulationDocuments.filter(
                        x => x.mappingType === MappingType.GENERALDOCUMENT &&
                        x.checked === true
                      ).length
                    }
                    scope="colgroup"
                    className="grid-top-header"
                    hidden = {regulationDocuments.filter(
                              x => x.mappingType === MappingType.GENERALDOCUMENT &&
                              x.checked === true
                              ).length === 0 ? true : false}
                  >
                    <div title={"General Documents ("+categoryName+" only)"}>General Documents ({categoryName} only)</div>
                  </th>
                  
                  <th
                    colSpan={
                      regulationDocuments.filter(
                        x => x.mappingType === MappingType.REGULATION &&
                        x.checked === true
                      ).length
                    }
                    scope="colgroup"
                    className="grid-top-header"
                    hidden = {regulationDocuments.filter(
                              x => x.mappingType === MappingType.REGULATION &&
                              x.checked === true
                              ).length === 0 ? true : false}
                  >
                    <div title={"Requirements ("+categoryName+" only)"}>Requirements ({categoryName} only)</div>
                  </th>

                  <th
                    colSpan={
                      regulationDocuments.filter(
                        x => x.mappingType === MappingType.GENERALDOCUMENT &&
                        x.checked === false
                      ).length
                    }
                    scope="colgroup"
                    className="grid-top-header"
                    hidden = {regulationDocuments.filter(
                              x => x.mappingType === MappingType.GENERALDOCUMENT &&
                              x.checked === false
                              ).length === 0 ? true : false}
                  >
                    <div title={"General Documents (others)"}>General Documents (others)</div>
                  </th>
                  
                  <th
                    colSpan={
                      regulationDocuments.filter(
                        x => x.mappingType === MappingType.REGULATION &&
                        x.checked === false
                      ).length
                    }
                    scope="colgroup"
                    className="grid-top-header"
                    hidden = {regulationDocuments.filter(
                              x => x.mappingType === MappingType.REGULATION &&
                              x.checked === false
                              ).length === 0 ? true : false}
                  >
                    <div title={"Requirements (others)"}>Requirements (others)</div>
                  </th>
                </tr>
                <tr>
                  <th className="first-column"></th>

                  {regulationDocuments.map((reg, col) => (
                    <th
                      key={reg.id + "_" + reg.mappingType}
                      className={
                        (col === regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === true).length - 1)
                        ||
                        (col === regulationDocuments.filter(x => x.checked === true).length - 1)
                        ||
                        (col === (regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === false).length)
                        +(regulationDocuments.filter(x => x.checked === true).length) - 1)
                          ? reg.isActive
                            ? "last-document standard-name"
                            : "last-document inactive standard-name"
                          : reg.isActive
                          ? " standard-name"
                          : "inactive standard-name"
                      }
                    >
                      <div title={reg.name}>{getEllipsis(reg.name, 60)}</div>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th className="first-column"></th>
                  {regulationDocuments.map((reg, col) => (
                    <th
                      key={reg.id + "_" + reg.mappingType}
                      className={
                        (col === regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === true).length - 1)
                        ||
                        (col === regulationDocuments.filter(x => x.checked === true).length - 1)
                        ||
                        (col === (regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === false).length)
                        +(regulationDocuments.filter(x => x.checked === true).length) - 1)
                          ? reg.isActive
                            ? "last-document standard-identifier"
                            : "last-document inactive standard-identifier"
                          : reg.isActive
                          ? " standard-identifier"
                          : "inactive standard-identifier"
                      }
                    >
                      <div title={reg.identifier}>{reg.identifier}</div>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th className="first-column"></th>
                  {regulationDocuments.map((reg, col) => (
                    <th
                      key={reg.id + "_" + reg.mappingType}
                      className={
                        (col === regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === true).length - 1)
                        ||
                        (col === regulationDocuments.filter(x => x.checked === true).length - 1)
                        ||
                        (col === (regulationDocuments.filter(x => x.mappingType === MappingType.GENERALDOCUMENT && x.checked === false).length)
                        +(regulationDocuments.filter(x => x.checked === true).length) - 1)
                          ? reg.isActive
                            ? "last-document standard-description"
                            : "last-document inactive standard-description"
                          : reg.isActive
                          ? " standard-description"
                          : "inactive standard-description"
                      }
                    >
                      <div title={reg.description}>{getEllipsis(reg.description, 30)}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>                
                {
                   [...Array(gridData.length / regulationDocuments.length)].map(
                      (hier, row) => (
                      <tr key={row}>
                        <td className="first-column">
                          <div
                            className={productHierarchyData[row].isActive ? productHierarchyData[row].type : productHierarchyData[row].type + " inactive"}
                            key={productHierarchyData[row].id}
                            >
                            <div className="productList" title={productHierarchyData[row].name}>{getEllipsis(productHierarchyData[row].name, 40)}</div>
                            <div className="hierarchyCode">&nbsp;&nbsp;&nbsp;{productHierarchyData[row].hierarchyCode}</div>
                          </div>
                        </td>
                        {regulationDocuments.map((reg, col) => (
                          <td key={row + "_" + col}>
                            <MappingCheckbox
                              index={
                                gridData[row * regulationDocuments.length + col]
                                  .index
                              }
                              isActive={
                                gridData[row * regulationDocuments.length + col]
                                  .isActive
                              }
                              checked={
                                gridData[row * regulationDocuments.length + col]
                                  .checked
                              }
                              onClick={index => handleMappingChange(index)}
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default MappingTable;
