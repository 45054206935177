import React, { useState, useEffect, Fragment } from "react";
import {
  Form,
  Card,
  InputGroup,
  Section,
  SelectField,
  TextInputField,
  TextAreaField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  RadioButtonField
} from "luna-react";
import HttpStatus from "../../res/HttpStatus";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import { useSaveGeneralDocuments, useGetGeneralDocuments } from "../../api/generalDocumentService";
import PopupComponent from "../popup/Popup";

const GeneralDocumentForm = ({ history, location }) => {
  const [fields] = useState({
    standard: "Standard *",
    identifier: "Identifier *",
    description: "Description",
    scope: "Scope",
    note: "Notes",
    appliesTo: "Applies To *"
  });

  const initialForm = {
    id: null,
    standard: "",
    identifier: "",
    description: "",
    note: "",
    appliesTo: "",
    isActive: true,
    timestamp: Math.random(),
    mode: "Add"
  };

  const initialError = {
    standard: "",
    identifier: "",
    appliesTo: ""
  };

  const [{ data, isLoading, isDataError }, setQuery] = useGetGeneralDocuments();
  const [searchQuery, setSearchQuery] = useState();
  const [error, setError] = useState(initialError);
  const [form, setForm] = useState(initialForm);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [modalShow, setModalShow]=useState(false);
  const [showPopUp, setPopUpPage] =useState();
  const [documentId, setDocumentId]=useState();
  const [
    { isSaved, isError, errorCode, errorMessage },
    setFormData
  ] = useSaveGeneralDocuments();

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
  
      }
      else{
        const max = Math.max.apply(null, data.map(item => item.id));
        const docId = data.find(x=>x.id === max && x.standard===form.standard);
        if(docId){
          setDocumentId(docId.identifier);
        }    
      }
    }
  }, [data]);

  useEffect(() => {
    if (isDataError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDataError]);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.generalDocument) {
        let generalDocument = location.state.generalDocument;
        generalDocument.mode = "Edit";
        setForm(generalDocument);
      }
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      if (errorCode === HttpStatus.STATUS_409_CONFLICT) {
        setDuplicateErrorMessage();

        utils.scrollToError();
      } else {
        utils.toastError(strings.common.apiSaveErrorMessage);
      }
      setIsFormSubmitting(false);
    }
  }, [isError, errorCode]);

  const setDuplicateErrorMessage = () => {
    if(errorMessage.includes('Identifier')){
      setError({
        ...error,
        identifier: errorMessage
      });
    }
    else{
      setError({
        ...error,
        standard: errorMessage
      });
    }
  }

  useEffect(() => {
    setSearchQuery(
      `searchData=${form.standard}&isActive=true&ts=${Math.random()}`
    );
    if (isSaved) {
      setIsFormSubmitting(false);
      if(modalShow && documentId && form.mode==="Add"){
        setPopUpPage(<PopupComponent show={modalShow} handleClose = {closeModal} 
                      displayContent = {setPopupText(documentId, form.standard)}/>)
      }
      if(form.mode==="Edit"){
        utils.toastSuccess(strings.common.apiSaveSuccessMessage);
        handleBack();
      }
    }
  }, [isSaved, data]);

  const setPopupText = (documentId, standardName) => {
    let popupMessage = (<p>Name of the Document standard: {standardName}<br/>
                        Standard Identifier: {documentId}</p>)
    return popupMessage;
  }
  const closeModal = () => {
    setPopUpPage(null);
    utils.toastSuccess(strings.common.apiSaveSuccessMessage);
    handleBack();
  }

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "radio"
          ? event.target.value === "Active"
            ? true
            : false
          : event.target.value
    });
  };

  const handleBlur = event => {
    setForm({ ...form, [event.target.name]: event.target.value.trim() });
  };

  const handleSubmit = event => {
    event.preventDefault();

    // Timestamp change if form isn't changed before submission, add/update button will stay disabled without this.
    setForm({ ...form, timestamp: Math.random() });

    if (handleFormValidation()) {
      setIsFormSubmitting(true);
      setFormData(form);
      setModalShow(true);
    } else {
      setTimeout(function() {
        utils.scrollToError();
      }, 100);
    }
  };

  const handleFormValidation = () => {
    let _error = initialError;
    let passed = true;

    //eslint-disable-next-line
    const isInValidFileName = /\||\\|\/|\#|\{|\}|\[|\]|\=|\+|\£|\$|\%|\^|\:/g;

    if (!form.standard) {
      _error.standard = "Standard is required";
      passed = false;
    }

    if (!form.identifier) {
      _error.identifier = "Identifier is required";
      passed = false;
    }

    if (!form.appliesTo) {
      _error.appliesTo = "Applies To is required";
      passed = false;
    }

    setError(_error);

    return passed;
  };

  const handleBack = () => {
    setForm(initialForm);
    setError(initialError);
    history.push("/generaldocument");
  };

  return (
    <Fragment>
    {showPopUp ? showPopUp : null}
    <Card className="card-padding">
      <Form className="o-form" id="general-document-form">
        <h2>
          {form.mode === "Add"
            ? "Add General Document"
            : "Edit General Document"}
        </h2>
        <Section>
          <TextInputField
            id="standard-input"
            name="standard"
            label={fields.standard}
            value={form.standard}
            onChange={handleChange}
            maxLength={1000}
            onBlur={handleBlur}
            error={error.standard}
            required={true}
          />
          <TextInputField
            name="identifier" 
            value = {form.identifier}
            label = {fields.identifier}            
            onBlur={handleBlur}
            onChange={handleChange}
            error={error.identifier}
            required={true}
          /> 
          <TextInputField
            id="description-input"
            name="description"
            label={fields.description}
            value={form.description}
            maxLength={1000}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
          />
          <TextAreaField
            id="note-input"
            name="note"
            label={fields.note}
            value={form.note}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
          />
          <TextAreaField
            id="applies-to-input"
            name="appliesTo"
            label={fields.appliesTo}
            value={form.appliesTo}
            onBlur={handleBlur}
            onChange={handleChange}
            error={error.appliesTo}
            required={true}
          />
          {form.mode === "Edit" && (
            <RadioButtonField
              id="document-status"
              label={<span>Status</span>}
              name="isActive"
              listType="inline"
              value={form.isActive ? "Active" : "Inactive"}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" }
              ]}
              onChange={handleChange}
            />
          )}
          <ButtonGroupWrapper>
            <ButtonGroupPrimary>
              <button
                id="add-update-document"
                type="button"
                className="ln-c-button ln-c-button--primary"
                onClick={event => handleSubmit(event)}
                disabled={isFormSubmitting}
              >
                {form.mode === "Add" ? "Add" : "Update"}
              </button>
            </ButtonGroupPrimary>
            <ButtonGroupSecondary>
              <button
                id="cancel-form"
                type="button"
                className="ln-c-button ln-c-button--outlined"
                onClick={handleBack}
              >
                Back
              </button>
            </ButtonGroupSecondary>
          </ButtonGroupWrapper>
        </Section>
      </Form>
    </Card>
    </Fragment>
  );
};

export default GeneralDocumentForm;
