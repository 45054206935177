import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  Label,
  Card
} from "luna-react";
import PropTypes from "prop-types";
import { HierarchyType } from "../../res/enums";
import { uuidGenerator } from "./utils";

import "../../index.scss";

const HierarchyTableRegulation = ({
  title,
  gridData,
  productHierarchyId,
  level,
  callbackExcludedRegulations
}) => {
  const [localRegulation, setLocalRegulation] = useState();

  useEffect(() => {
    if (gridData) {
      setLocalRegulation(
        gridData.map(regulation => ({ ...regulation, checked: false }))
      );
    }
  }, [gridData]);

  const handleSelectRow = (regulationId, isChecked) => {
    const updatedRegulations = [...localRegulation];
    const updatedRegulation = updatedRegulations.find(
      regulation => regulation.id == regulationId
    );

    if (updatedRegulation) {
      updatedRegulation.checked = isChecked;
    }

    setLocalRegulation(updatedRegulations);
    gatherExcludedRegulations(updatedRegulations);
  };

  const gatherExcludedRegulations = regulations => {
    const excludedRegulations = regulations
      .filter(regulation => regulation.checked)
      .map(regulation => {
        return {
          productHierarchyId,
          productHierarchyLevel: level,
          regulationId: regulation.id
        };
      });

    callbackExcludedRegulations(productHierarchyId, excludedRegulations);
  };

  return (
    <Card className="padding" key={uuidGenerator()}>
      <Label htmlFor="">{title}</Label>
      <TableContainer responsive id="selected-regulation-table">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell scope="colgroup">Standard</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Identifier</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Description</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Scope</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Note</TableHeaderCell>
            <TableHeaderCell scope="colgroup">Pass Requirement</TableHeaderCell>
            <TableHeaderCell scope="colgroup">
              Proof of Compliance
            </TableHeaderCell>
            <TableHeaderCell scope="colgroup" className="fixed-table-width">
              Not Required
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {localRegulation &&
            localRegulation.length > 0 &&
            localRegulation.map(row => (
              <TableRow key={uuidGenerator()}>
                <TableCell>{row.standard}</TableCell>
                <TableCell>{row.identifier}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.scope}</TableCell>
                <TableCell>{row.note}</TableCell>
                <TableCell>{row.passRequirement}</TableCell>
                <TableCell>{row.proofOfCompliance}</TableCell>
                <TableCell lastWhenStacked className="ln-u-hard-ends">
                  <Checkbox
                    id={row.id}
                    name={`${row.id}-${uuidGenerator()}`}
                    inline
                    label={`Select ${row.name}`}
                    hideLabel
                    checked={row.checked}
                    onChange={e => handleSelectRow(row.id, e.target.checked)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
    </Card>
  );
};

export default HierarchyTableRegulation;

HierarchyTableRegulation.propTypes = {
  title: PropTypes.string.isRequired,
  gridData: PropTypes.array.isRequired,
  productHierarchyId: PropTypes.number.isRequired,
  level: PropTypes.oneOf([HierarchyType.PRODUCTCLASS, HierarchyType.OPTION])
    .isRequired,
  callbackExcludedRegulations: PropTypes.func.isRequired
};
