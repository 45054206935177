import azureInit from "@jsainsburyplc/colleague-auth";

export function azureAd() {
  azureInit({
    clientId: window.appConfig.AzureAd.Sainsburys.ClientId,
    tenantId: window.appConfig.AzureAd.Sainsburys.TenantId,
    redirectUri: window.appConfig.frontendRootUrl
  });
}

export const hasRole = (userRoles, roles) =>
  roles.some(role => userRoles.split(",").includes(role));

export let user = {};

export function setUser(roles, name) {
  user = {
    roles: roles,
    name: name
  };
}
