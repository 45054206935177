import React, { useState, useEffect } from "react";
import SortedTable from "../shared/SortedTable";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import {MappingType} from "../../res/enums";
import { useGetGeneralDocuments } from "../../api/generalDocumentService";
import { useProductMappingReport } from "../../api/reverseMappingService";

const GeneralDocumentGrid = ({ history, searchQuery, setIsLoading }) => {
  const [isEditRowActive, setIsEditRowActive] = useState();
  const [fileNamePart, setFileNamePart] = useState();

  const [columnIds] = useState({
    Standard: "standard",
    Identifier: "identifier",
    Description: "description",
    Notes: "note",
    "Applies To": "appliesTo",
    Action: "action"
  });

  const [{ data, isLoading, isError }, setQuery] = useGetGeneralDocuments();
  const [
    { reportData, isDownloading, isDownloaded, isDownloadError, setMappingReportData }
  ] = useProductMappingReport();

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
    }
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(()=>{
    if(reportData){
      fileDownload();
    }
  },[reportData])

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };
 
    const filename = `${fileNamePart} Mapping - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";
 
    const file = new Blob([reportData], blobType);
 
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
 
    let a = document.createElement("a");
 
    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();
 
    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  const onClick = generalDocumentId => {
    let generalDocumentModel = data.find(x => x.id === generalDocumentId);

    history.push({
      pathname: "/generaldocument/" + generalDocumentId,
      state: { generalDocument: generalDocumentModel }
    });
  };

  const handleEditToggle = i => {
    setIsEditRowActive(i);
  };

  const generateReport = generalDocumentId =>  {
    const mappingReport = Object.assign({
      MappingType: MappingType.GENERALDOCUMENT,
      Id: generalDocumentId
    });
    let generalDocumentModel = data.find(x => x.id === generalDocumentId);
    setFileNamePart(generalDocumentModel.identifier);
    setMappingReportData(mappingReport);
  }

  const reverseMapping = generalDocumentId => {
    let generalDocumentModel = data.find(x => x.id === generalDocumentId);
    history.push({
      pathname: "/generaldocument/reversemapping/" + generalDocumentId,
      state: { generalDocument: generalDocumentModel }
    });
  }

  return (
    <>
      {data && data.length > 0 ? (
        <SortedTable
          id={"general-documents-table"}
          data={data}
          isEditRowActive={isEditRowActive}
          onEditToggle={handleEditToggle}
          columnIds={columnIds}
          onClick={onClick}
          generateReport={generateReport}
          reverseMapping={reverseMapping}
          diableReportGeneration={true}
        />
      ) : null}
    </>
  );
};

export default GeneralDocumentGrid;
