import React, { useState, Fragment, useEffect } from "react";

import UserForm from "./UserForm";

import PageTitleBar from "../shared/PageTitleBar";
import SearchBar from "../shared/SearchBar";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";

import "./index.scss";

import { useGetUsers, useGetExportRequirments } from "../../api/userAdminService";
import SortedTable from "../shared/SortedTable";

const UserAdmin = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditRowActive, setIsEditRowActive] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [user, setUser] = useState();
  const [columnIds] = useState({
    Name: "name",
    Email: "email",
    Roles: "roles",
    Action: "action"
  });

  const [{ data, isLoading, isError }, setQuery] = useGetUsers();
  const [{ exportData, isDownloading, isDownloaded, isDownloadError, setExportData}] = useGetExportRequirments();

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
    }
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    setIsFetching(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (searchQuery) {
      refreshData();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (exportData) {
      fileDownload();
    }
  }, [exportData]);

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };

    const filename = `User List - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";

    const file = new Blob([exportData], blobType);

    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    let a = document.createElement("a");

    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  const handleSearch = (term, status) => {
    setSearchQuery(`name=${term}&isActive=${status}&ts=${Math.random()}`);
  };

  const handleOnClick = userId => {
    if (data && data.length > 0) {
      let userModel = data.find(x => x.id === userId);
      setUser(userModel);
      setIsFormVisible(true);
    }
  };

  const handleTitleClick = () => {
    setIsFormVisible(true);
    setUser();
    setIsEditRowActive();
  };

  const handleEditToggle = i => {
    setIsEditRowActive(i);
  };

  const refreshData = () => {
    setQuery(`${searchQuery}&ts=${Math.random()}`);
  };

  const handleDownload = () => {
    setExportData(`ts=${Math.random()}`);
  }

  return (
    <Fragment>
      <PageTitleBar
        title={"Users"}
        isLoading={isFetching}
        onClick={handleTitleClick}
      />
      <SearchBar placeholder="Enter a name to search" 
        onSearch={handleSearch} 
        downloadButton={true}
        handleDocumentDownload = {handleDownload}
      />
      {data && data.length > 0 ? (
        <SortedTable
          id={"users-table"}
          data={data}
          isEditRowActive={isEditRowActive}
          onEditToggle={handleEditToggle}
          columnIds={columnIds}
          onClick={handleOnClick}
          diableReportGeneration={false}
        />
      ) : null}
      {isFormVisible ? (
        <UserForm
          user={user}
          setIsFormVisible={setIsFormVisible}
          setUser={setUser}
          onEditToggle={handleEditToggle}
          notifyFormSubmissionDone={refreshData}
          diableReportGeneration={false}
        />
      ) : null}
    </Fragment>
  );
};

export default UserAdmin;
