import React, { Fragment, useState, useEffect } from "react";

import PageTitleBar from "../shared/PageTitleBar";
import SearchBar from "../shared/SearchBar";

import { useGetRegulations } from "../../api/regulationService";
import { useExportRequirementGeneralDocuments } from "../../api/requirementGeneralDocumentExportService";

import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import LoadingNotification from "../shared/LoadingNotification";
import RegulationGrid from "./RegulationGrid";

const Regulation = props => {
  const [searchQuery, setSearchQuery] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [getRequirementQuery, setRequirementQuery] = useState();

  const [{ data, requirementLoading, isError }, setQuery] = useGetRegulations();
  const [
    { exportData, isDownloading, isDownloaded, isDownloadError, setExportData }
  ] = useExportRequirementGeneralDocuments();

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
      else{
        const requirementGeneralDocumentExport = Object.assign(
          {generalDocumentData : null},
          {regulationData : data}
        );
        setExportData(requirementGeneralDocumentExport);
      }
    }
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
      setQuery(getRequirementQuery);
  }, [getRequirementQuery]);

  useEffect(() => {
    if (isDownloadError) {
      utils.toastError(strings.common.apiDownloadErrorMessage);
    }
  }, [isDownloadError]);

  useEffect(() => {
    if (exportData) {
      fileDownload();
    }
  }, [exportData]);

  const handleSearch = (term, status) => {
    setSearchQuery(
      `searchData=${term}&isActive=${status}&ts=${Math.random()}`
    );
  };

  const handleTitleClick = () => {
    props.history.push("/regulation/0");
  };

  const handleDownload = () => {
    setRequirementQuery(`ts=${Math.random()}`);
 }

 const fileDownload = () => {
   const blobType = { type: "application/vnd.ms-excel" };

   const filename = `Requirements - ${new Date().toLocaleString()}`;
   const filenameType = ".xlsx";

   const file = new Blob([exportData], blobType);

   //Build a URL from the file
   const fileURL = URL.createObjectURL(file);

   let a = document.createElement("a");

   a.download = filename.concat(filenameType);
   a.href = fileURL;
   document.body.appendChild(a);
   a.click();

   setTimeout(function() {
     window.URL.revokeObjectURL(fileURL);
     document.body.removeChild(a);
   }, 0);
 };

  return (
    <Fragment>
      <PageTitleBar
        title={"Requirements"}
        isLoading={isLoading}
        onClick={handleTitleClick}
      />
      {isDownloading && !isDownloaded && (
        <LoadingNotification notificationOpen={true} />
      )}
      <SearchBar
        placeholder="Enter text to search"
        onSearch={handleSearch}
        downloadButton = {true}
        handleDocumentDownload = {handleDownload}
      />
      <RegulationGrid
        history={props.history}
        searchQuery={searchQuery}
        setIsLoading={setIsLoading}
      />
    </Fragment>
  );
};

export default Regulation;
