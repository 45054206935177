import { api } from "../api/api";

const apiUrl = `${window.appConfig.rootUrl}/Mappings/`;

export function useGetMappings() {
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(apiUrl);

  return [{ data, isLoading, isError }, setQuery];
}

export function useGetDownload() {
  const [
    { data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ] = api.useDataDownload(`${apiUrl}GetDownload/`);

  return [
    { exportData: data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ];
}

export function useSaveMappings() {
  const [{ isSaved, isError }, setFormData] = api.useDataSave(apiUrl);

  return [{ isSaved, isSavedError: isError }, setFormData];
}