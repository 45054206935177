import React, { Fragment, useState, useEffect } from "react";
import SortedTable from "../shared/SortedTable";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import {MappingType} from "../../res/enums";
import { useGetRegulations } from "../../api/regulationService";
import { useProductMappingReport } from "../../api/reverseMappingService";

const RegulationGrid = ({ history, searchQuery, setIsLoading }) => {
  const [isEditRowActive, setIsEditRowActive] = useState();
  const [fileNamePart, setFileNamePart] = useState();
  const [columnIds] = useState({
    Standard: "standard",
    Identifier: "identifier",
    Description: "description",
    Scope: "scope",
    "Pass Requirements": "passRequirement",
    Action: "action"
  });

  const [{ data, isLoading, isError }, setQuery] = useGetRegulations();
  const [
    { reportData, isDownloading, isDownloaded, isDownloadError, setMappingReportData }
  ] = useProductMappingReport();
  const [tableData, setTableData]=useState();

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
      else{
        const sortedTabledata = [];
        data.map(x=>{
          sortedTabledata.push({
            id: x.id,
            isActive: x.isActive,
            standard: x.standard,
            identifier: x.identifier,
            description: x.description,
            scope: x.scope,
            passRequirement: x.passRequirement
          });
        });
        setTableData(sortedTabledata);
      }
    }
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  useEffect(()=>{
    if(reportData){
      fileDownload();
    }
  },[reportData])

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };
    const filename = `${fileNamePart} Mapping - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";
 
    const file = new Blob([reportData], blobType);
 
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
 
    let a = document.createElement("a");
 
    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();
 
    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  const onClick = regulationId => {
    let regulationModel = data.find(x => x.id === regulationId);

    history.push({
      pathname: "/regulation/" + regulationId,
      state: { regulation: regulationModel }
    });
  };

  const handleEditToggle = i => {
    setIsEditRowActive(i);
  };

  const generateReport = regulationId =>  {
    const mappingReport = Object.assign({
      MappingType: MappingType.REGULATION,
      Id: regulationId
    });
    let regulationModel = data.find(x => x.id === regulationId);
    setFileNamePart(regulationModel.identifier);
    setMappingReportData(mappingReport);
  }

  const reverseMapping = regulationId => {
    let regulationModel = data.find(x => x.id === regulationId);
    history.push({
      pathname: "/regulation/reversemapping/" + regulationId,
      state: { regulation: regulationModel }
    });

  }
  return (
    <>
      {tableData && tableData.length > 0 ? (
        <SortedTable
          data={tableData}
          isEditRowActive={isEditRowActive}
          onEditToggle={handleEditToggle}
          columnIds={columnIds}
          onClick={onClick}
          generateReport={generateReport}
          reverseMapping={reverseMapping}
          diableReportGeneration={true}
        />
      ) : null}
    </>
  );
};

export default RegulationGrid;
