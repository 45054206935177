import React, { useState, useEffect } from "react";
import {
  Form,
  TextInputField,
  RadioButtonField,
  OutlinedButton,
  FilledButton,
  Label,
  GridWrapper,
  GridItem
} from "luna-react";
import { useSaveProductHierarchy, useGetClassHierarchyCode, useOptionGetHierarchyCode } from "../../api/productHierarchyService";

import "./index.scss";
import * as utils from "../shared/Utils";
import strings from "../../res/strings";
import HttpStatus from "../../res/HttpStatus";

const HierarchyForm = ({
  hierarchyType,
  parentHeading,
  parentId,
  displayFormData,
  notifyFormSubmissionDone
}) => {
  const [{optionCodeData, optionCodeLoading, optionCodeError}, {setOptionHierarchyCode}] = useOptionGetHierarchyCode();
  const [optionHierarchyCodeQuery, setOptionHierarchyCodeQuery]=useState();
  const isActiveRadioName = `${hierarchyType}IsActive`;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const initialForm = {
    id: null,
    name: "",
    parentId: parentId,
    isActive: true,
    classCodePrefix:"",
    hierarchyCode: "",
    timestamp: Math.random(),
    mode: "Add"
  };

  const [form, setForm] = useState({
    id: null,
    name: "",
    parentId: parentId,
    isActive: true,
    classCodePrefix:"",
    hierarchyCode: "",
    timestamp: Math.random(),
    mode: "Add"
  });

  const initialError = {
    name: "",
    isActive: "",
    parentId: "",
    classCodePrefix:"",
    hierarchyCode: "",
  };

  const [error, setError] = useState(initialError);

  const [{classHierarchyCode, classCodeLoading, classCodeError}, {setClassHierarchyCode}] = useGetClassHierarchyCode();
  const [
    { isSaved, isError, errorCode, errorMessage },
    setFormData
  ] = useSaveProductHierarchy();

  useEffect(()=>{
    if(classHierarchyCode){
      if(classHierarchyCode.length===0){
        utils.toastError(strings.common.noDataMessage);
      }
      else{
        form.hierarchyCode=classHierarchyCode;
        setForm({...form});
      }
    }
  },[classHierarchyCode,classCodeLoading]);


  useEffect(() => {
    setError(initialError);
    setForm({ ...displayFormData, timestamp: Math.random() });
  }, [displayFormData]);

  useEffect(()=>{
    if(optionCodeData){
      if(optionCodeData.length === 0){
        utils.toastError(strings.common.noDataMessage);
      }
      else{
        if(form.mode==="Add"){
          form.hierarchyCode=optionCodeData;
          setForm({ ...form});
        }
      }
    }
  },[optionCodeData, optionCodeLoading]);

  useEffect(()=>{
    if(optionCodeLoading){
      utils.toastError(strings.common.apiGetErrorMessage)
    }
  },[optionCodeError])

  useEffect(()=>{
    setOptionHierarchyCode(optionHierarchyCodeQuery);
  },[optionHierarchyCodeQuery]);

  const handleFormChange = event => {
    const formData = { ...form };
    if (event.target.name === isActiveRadioName) {
      formData.isActive = event.target.value;
    } 
    else if(event.target.name === "hierarchyCode"){
      formData.hierarchyCode = event.target.value;
    }
    else if(event.target.name === "classCodePrefix"){
      formData.classCodePrefix = event.target.value;
    }
    else {
      formData.name = event.target.value;
    }
    setForm(formData);
  };

  const validateForm = () => {
    let passed = true;
    let _error = initialError;

    if (!form.name) {
      _error.name = "Name is required";
      passed = false;
    }
    if(form.mode === "Add" && (hierarchyType === "ProductClass") && !form.classCodePrefix){
      _error.classCodePrefix = "Class code prefix is required";
      passed = false;
    }
    if ((hierarchyType === "ProductClass" || hierarchyType === "Option") && !form.hierarchyCode) {
      _error.hierarchyCode = "Hierarchy code is required";
      passed = false;
    }
    if (form.mode === "Edit" && form.isActive === null) {
      _error.isActive = "Status is required";
      passed = false;
    }
    if (!form.parentId && parentHeading) {
      _error.parentId = `${parentHeading} is required`;
      passed = false;
    }

    setError(_error);

    return passed;
  };

  useEffect(() => {
    if (isError) {
      if (errorCode === HttpStatus.STATUS_409_CONFLICT) {
        setDuplicateValidationError();
        if(hierarchyType==="Option"){
          setOptionHierarchyCodeQuery(`parentId=${form.parentId}&ts=${Math.random()}`);
        }
        else{
          setClassHierarchyCode(`classCode=${form.classCodePrefix}&ts=${Math.random()}`);
        }        
      } else {
        utils.toastError(strings.common.apiSaveErrorMessage);
      }
      setIsFormSubmitting(false);
    }
  }, [isError, errorCode]);

  const setDuplicateValidationError = () =>{
    if(errorMessage.includes('code')){
      setError({
        ...error,
        hierarchyCode: errorMessage + ". New code is generated, Please submit again"
      });
    }
    else{
      setError({
        ...error,
        name: errorMessage
      });
    }
  }

  useEffect(() => {
    if (isSaved) {
      utils.toastSuccess(strings.common.apiSaveSuccessMessage);
      handleCancel();
      notifyFormSubmissionDone(null);
      setIsFormSubmitting(false);
    }
  }, [isSaved]);

  const handleSubmit = event => {
    event.preventDefault();

    form.parentId = parentId;

    setForm({ ...form, timestamp: Math.random() });

    if (validateForm()) {
      setIsFormSubmitting(true);
      setFormData(form);
    }
  };

  const handleCancel = () => {
    setError(initialError);
    setForm(initialForm);
  };

  const codeValidation = () =>{
    let _error = initialError;
    let passed=true;
    var codeValue = /^[A-Za-z]+$/
    if(!form.classCodePrefix){
      _error.classCodePrefix = "Code is required";
      passed = false;
    }
    if(form.classCodePrefix && form.classCodePrefix.length!==2){
      _error.classCodePrefix="Invalid code";
      passed=false;
    }
    if(form.classCodePrefix && !form.classCodePrefix.match(codeValue)){
      _error.classCodePrefix="Alphabets only"
      passed=false;
    }
    if(form.mode==="Add" && !passed){
      form.hierarchyCode="";
    }
    setError(_error);
    return passed;
  }

  const getHierarchyCode=()=>{
    if(codeValidation()){
      setClassHierarchyCode(`classCode=${form.classCodePrefix}&ts=${Math.random()}`);
    }
  }

  const getOptionHierarchyCode = () =>{
    //pass the id of the class for which options belong to inorder to get the hierarchy code
    if(hierarchyType==="Option"){
      if(form.parentId!=null && form.hierarchyCode===""){
        setOptionHierarchyCodeQuery(`parentId=${form.parentId}&ts=${Math.random()}`);
      }
    }
  }
  return (
    <Form
      id="product-hierarchy-form"
      className="o-form"
      onSubmit={event => handleSubmit(event)}
    >
      {error.parentId && (
        <div className="ln-c-form-group has-error" data-reactroot="">
          <div
            id="text-input-field-2Validation"
            className="ln-c-field-info ln-c-field-info--error"
            role="alert"
          >
            {error.parentId}
          </div>
        </div>
      )}
      <TextInputField
        name="name"
        label="Name *"
        maxLength={256}
        required
        error={error.name}
        value={form.name}
        onChange={handleFormChange}
        onClick={getOptionHierarchyCode}
      />
      {hierarchyType==="Option" &&(
        <TextInputField
          name="hierarchyCode"
          label="Hierarchy Code"
          required
          error={error.hierarchyCode}
          value={form.hierarchyCode}
          onChange={handleFormChange}
          disabled
        />
      )}
      {
        (hierarchyType==="ProductClass")&&
          (
          <>
            {form.mode==="Add" && (
            <>
              <Label htmlFor="">Class Code Prefix *</Label>
              <GridWrapper className="code-generator">
                <GridItem size={"2/3"} element="li">
                  <TextInputField
                  name="classCodePrefix"
                  value={form.classCodePrefix}
                  required
                  error={error.classCodePrefix}
                  onChange={handleFormChange}
                  />
                </GridItem>
                <GridItem size={"1/3"} element="li" className="class-code-button">
                  <OutlinedButton
                    className="ln-c-button"
                    onClick={getHierarchyCode}>
                      Check
                  </OutlinedButton>
                </GridItem>
              </GridWrapper>
            </>
            )}
            {form.hierarchyCode &&(
              <TextInputField
                name="hierarchyCode"
                label="Hierarchy Code"
                disabled
                value={form.hierarchyCode}
                error={error.hierarchyCode}
              />
            )}
          </>
          )
      }

      {form.mode === "Edit" && (
        <RadioButtonField
          label="Status"
          info={"An inactive item is not visible on Test Requirements forms"}
          name={isActiveRadioName}
          listType="inline"
          required
          error={error.isActive}
          value={form.isActive}
          options={[
            { value: "true", label: "Active" },
            { value: "false", label: "Inactive" }
          ]}
          onChange={handleFormChange}
        />
      )}

      <div className="ln-o-clearfix" data-reactroot="">
        <div className="ln-c-button-group__primary">
          <FilledButton type="submit" disabled={isFormSubmitting}>
            {form.mode === "Add" ? "Add" : "Update"}
          </FilledButton>
        </div>
        <div className="ln-c-button-group__secondary">
          {form.mode === "Edit" && (
            <OutlinedButton
              className="ln-u-margin-bottom*2"
              onClick={() => handleCancel()}
            >
              Cancel
            </OutlinedButton>
          )}
        </div>
      </div>
    </Form>
  );
};

export default HierarchyForm;
