import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Container } from "luna-react";
import { ToastContainer } from "react-toastify";
import { Header as LunaHeader } from "luna-react";
import { Sainsburys } from "luna-images";
import { redirectToLogout} from "@jsainsburyplc/colleague-auth";

class Error extends React.Component {
  handleLogoutClick() {
    redirectToLogout();
  }

  render() {
    return (
      <>
        <ToastContainer autoClose={3000} hideProgressBar />
        <LunaHeader
          logo={<div></div>}
          topBar={{
            logo: (
              <Sainsburys
                height="25"
                width="95"
                className="ln-u-display-block"
              />
            ),
            meta: (
              <a href="#logout" onClick={this.handleLogoutClick}>
                Logout
              </a>
            )
          }}
        />
        <BrowserRouter>
          <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom">
            <Container size="xs">
              <br />
              <h2 className="ln-u-color-red">Error</h2>
              <h4>{this.props.ErrorMessage}</h4>
            </Container>
          </Container>
        </BrowserRouter>
      </>
    );
  }
}

export default Error;
