import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell, Checkbox } from "luna-react";
import strings from "../../res/strings";
import * as utils from "../shared/Utils";
import { useSaveMappings } from "../../api/mappingService"
import { useGetReverseMappings } from "../../api/reverseMappingService"
import { uuidGenerator } from "./utils";

const ReverseMappingComponent = ({ mappingModel, viewParameter, onSave, setIsLoading }) => {

  const [{ mappingData, isFetching, mappingError }, {setReverseMappingQuery}]=useGetReverseMappings();
  const [{ isSaved, isSaveError }, setFormData] = useSaveMappings();
  
  const [productHierarchies, setProductHierarchies]=useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(()=>{
    if(mappingData){  
      populateReverseMapping(mappingData, productHierarchies);
    }    
  },[mappingData, productHierarchies]);

  useEffect(()=>{
    if(viewParameter){
      if (!viewParameter.productHierarchy.length) {
        utils.toastInfo(strings.common.noDataMessage);
      } else 
      {
        setProductHierarchies(viewParameter);
        if(mappingModel){
          setReverseMappingQuery(`id=${mappingModel.id}&mappingType=${mappingModel.mappingType}&ts=${Math.random()}`); 
        }
      }
    }
  },[viewParameter]);

  const populateReverseMapping = (mappingData, productHierarchies) => {
    const gridItems=[];
    productHierarchies.productHierarchy.map(hierarchy=>{
      gridItems.push({
        id: hierarchy.id,
        categoryName: hierarchy.categoryName,
        subcategoryName: hierarchy.subcategoryName,
        productClassName: hierarchy.productClassName,
        productHierachyCode: hierarchy.productHierachyCode,
        optionName: hierarchy.optionName,
        optionHierarchyCode: hierarchy.optionHierarchyCode,
        itemId: mappingModel.id,
        mappingType: mappingModel.mappingType,
        checked: false,
        isActive: false,
        isChanged: false
      });
    });

    mappingData.map(m => {
      const item = gridItems.find(
        x =>
          x.id === m.productHierarchyId &&
          x.itemId === m.itemId &&
          x.mappingType === m.mappingType
      );
      if(item){
        item.checked = m.isActive;
        item.isActive = m.isActive;
      }
    });
    setGridData(gridItems);
  }

  const handleMappingChange = (regulationId, isChecked) => {
    const gridItems = [...gridData];
    const updatedItem = gridItems.find(
      x=>x.id==regulationId
    );
    if(updatedItem){
      updatedItem.checked = isChecked;
      updatedItem.isChanged = updatedItem.isChanged ? false : true;
    }
    setGridData(gridItems);
  };

  useEffect(()=>{
    if (onSave) {
      const savedData = gridData.filter(x=>x.isChanged)
        .map(x => {
          return {
            mappingType: x.mappingType,
            productHierarchyId: x.id,
            itemId: x.itemId,
            isActive: !x.isActive || x.checked
          };
        });
      if (savedData.length === 0) {
        utils.toastInfo(strings.common.noUpdatesToSave);
      } 
      else {
        setFormData(savedData);
        setIsLoading(true);
      }
    }
  },[onSave])

  useEffect(() => {
    if (isSaveError) {
      utils.toastError(strings.common.apiSaveErrorMessage);
      setIsLoading(false);
    }
  }, [isSaveError]);

  useEffect(() => {
    if (isSaved) {
      setReverseMappingQuery(`id=${mappingModel.id}&mappingType=${mappingModel.mappingType}&ts=${Math.random()}`); 
      utils.toastSuccess(strings.common.apiSaveSuccessMessage);
      setIsLoading(false);
    }
  }, [isSaved]);


  return(
   <Card>
     {
      gridData && gridData.length>0 && (
      <>
      <h3 className="reverse-mapping-title">Document Mapping</h3>
        <TableContainer responsive>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell scope="colgroup">Category</TableHeaderCell>
              <TableHeaderCell scope="colgroup">Sub Category</TableHeaderCell>
              <TableHeaderCell scope="colgroup">Product Class</TableHeaderCell>
              <TableHeaderCell scope="colgroup">Option</TableHeaderCell>
              <TableHeaderCell scope="colgroup">Hierarchy Code</TableHeaderCell>
              <TableHeaderCell scope="colgroup"></TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
              {gridData.map(row=>(
                <TableRow key={uuidGenerator()} className="product-hierarchy-row">
                  <TableCell>{row.categoryName}</TableCell>
                  <TableCell>{row.subcategoryName}</TableCell>
                  <TableCell>{row.productClassName}</TableCell>
                  <TableCell>{row.optionName}</TableCell>
                  <TableCell>{row.optionHierarchyCode?row.optionHierarchyCode:row.productHierachyCode}</TableCell>
                  <TableCell>
                    <Checkbox
                      name={`${row.id}-${uuidGenerator()}`}
                      id={row.id}
                      inline
                      checked={row.checked}
                      onChange={e => handleMappingChange(row.id, e.target.checked)}
                    />

                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TableContainer>
      </>
      )}
   </Card>
  );
}
export default ReverseMappingComponent;