import React, { useState, useEffect } from "react";
import {useGetDocumentIdentifier } from "../../api/regulationService";
import * as utils from "./Utils";
import strings from "../../res/strings";

import { EventEmitter } from "./EventEmitter";

export function useDropdownList(){
  const [{ data, isLoading, isDocumentError }, setQuery] = useGetDocumentIdentifier();
  useEffect(()=>{
    if (data) {
      if (data.length === 0) {
        utils.toastInfo(strings.common.noDataMessage);
      }
      else{
        const documentIdentifier=data.map(x=>{
          return{
            value: x.name,
            label: x.name
          }
        })
        EventEmitter.dispatch("dropdownValue", documentIdentifier);
      }
    }
  },[data]);

  useEffect(() => {
    if (isDocumentError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDocumentError]);

  useEffect(() => {
    setQuery(``);
  }, []); 
}