import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./index.scss";
import App from "./components/app/app";
import Error from "./components/error/error";
import * as serviceWorker from "./serviceWorker";
import { setUser, azureAd } from "./api/auth";
import { api } from "./api/api";
import { redirectToLogin, getToken, isLoggedIn } from "@jsainsburyplc/colleague-auth";

const mountElement = document.getElementById("root");

const StartApplication = () => {
  try {
    // Get authorisation
    api
      .getAuth(`${window.appConfig.rootUrl}/Auth`)
      .then(auth => {
        // Authorisation denied by the API because of signing in a wrong tenant
        if (!auth) {
          ReactDOM.render(
            <Error ErrorMessage="You're not currently authorised to access this application. Please make sure that you've signed in using a Sainsbury's email address." />,
            mountElement
          );
          return;
        }

        // The user account is disabled or has not been registered with the app
        if (!auth.isAuthorized) {
          if (
            typeof auth.name !== "undefined" &&
            auth.name !== null &&
            auth.name.length > 0
          ) {
            ReactDOM.render(
              <Error
                ErrorMessage={`Your account is not currently authorised to access this application. Please contact system administrator.`}
              />,
              mountElement
            );
            return;
          }
        }

        setUser(auth.roles, auth.name);
        ReactDOM.render(<App />, mountElement);
      })
      .catch(error => {
        ReactDOM.render(
          <Error ErrorMessage="The application is not available at the moment. Please contact system administrator." />,
          mountElement
        );
      });
  } catch (error) {
    ReactDOM.render(
      <div>
        Error starting application. Please contact system administrator.
      </div>,
      mountElement
    );
  }

  serviceWorker.unregister();
};

const main = () => {
azureAd();

  if (!isLoggedIn()) {
      redirectToLogin();
      return;
  } 
    StartApplication();
};

main();
