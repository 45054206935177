import React from "react";

const MappingCheckBox = ({ index, checked, isActive, onClick }) => {
  return (
    <span
      id={index}
      name={index}
      className={
        checked
          ? (isActive
          ? "mapping-checkbox mapping-checkbox-selected"
          : "mapping-checkbox mapping-checkbox-inactive")
          :"mapping-checkbox"
      }
      onClick={event => onClick(index)}
    ></span>
  );
};

export default MappingCheckBox;
