export const concatenatedRoles = target => {
  let concatenatedRoles = [];

  for (let i = 0; i < target.form.roles.length; i++) {
    if (target.form.roles[i].checked) {
      concatenatedRoles.push(target.form.roles[i].value);
    }
  }

  return concatenatedRoles.join();
};

export const covertRoleNamesToId = (userRoles, data) => {
  let roleIds = [];

  let selectedRoles = userRoles.split(",").map(function(item) {
    return item.trim();
  });

  data.forEach(function(element, i) {
    if (
      selectedRoles.includes(element.id) ||
      selectedRoles.includes(element.name)
    ) {
      roleIds.push(element.id);
    }
  });

  return roleIds.join();
};
