import React from "react";
import { Label, Card } from "luna-react";

const MappingLegend = () => {
  return (
    <Card className="card-padding" id="mapping-legend">
      <Label htmlFor="">Legend</Label>
      <ul className="ln-o-inline-list">
        <li className="ln-o-inline-list__item">
          <div className="ln-c-form-option ln-c-form-option--checkbox">
            <span className="mapping-checkbox mapping-checkbox-selected"></span>
            <label className="legend-label-spacing">
              <span>Selected</span>
            </label>
          </div>
        </li>
        <li className="ln-o-inline-list__item">
          <div className="ln-c-form-option ln-c-form-option--checkbox legend-div-spacing">
            <span className="mapping-checkbox mapping-checkbox-inactive"></span>
            <label>
              <span className="legend-label-spacing">Inactive</span>
            </label>
          </div>
        </li>
        <li className="ln-o-inline-list__item legend-div-spacing">
          <span className="ln-u-font-weight-bold">*</span>
          <label>
            <span className="legend-label-spacing">Inactive hierarchy</span>
          </label>
        </li>
        <li className="ln-o-inline-list__item legend-div-spacing">
          <span className="legend-inactive">Abc</span>
          <label>
            <span className="legend-label-spacing">Inactive option</span>
          </label>
        </li>
        <li className="ln-o-inline-list__item legend-div-spacing">
          <span className="ln-u-font-weight-bold">...</span>
          <label>
            <span className="legend-label-spacing">Ellipsis for long text</span>
          </label>
        </li>
      </ul>
    </Card>
  );
};

export default MappingLegend;
