import { api } from "../api/api";

const userApi = `${window.appConfig.rootUrl}/Users/`;
const roleApi = `${window.appConfig.rootUrl}/Roles/`;

export function useGetUsers() {
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(userApi);

  return [{ data, isLoading, isError }, setQuery];
}

export function useSaveUser() {
  const [
    { isSaved, isError, errorCode, errorMessage },
    setUserData
  ] = api.useDataSave(userApi);

  return [{ isSaved, isError, errorCode, errorMessage }, setUserData];
}

export function useGetRoles() {
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(roleApi);

  return [{ data, isLoading, isError }, setQuery];
}

export function useGetExportRequirments() {
  const [
    { data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ] = api.useDataDownload(`${userApi}GetDownload`);

  return [
    {
      exportData: data,
      isDownloading,
      isDownloaded,
      isDownloadError,
      setExportData: setFormData
    }
  ];
}