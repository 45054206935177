import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox, GridWrapper, GridItem } from "luna-react";
import { uuidGenerator } from "./utils";
import { EventEmitter } from "../shared/EventEmitter";

const HierarchySelectorOption = ({
  uuid,
  options,
  hierarchySelectorCounter
}) => {
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  const handleCheckbox = e => {
    // Splitting to remove the uuid after the '-'
    const optionId = e.target.name.split("-")[0];
    const isChecked = e.target.checked;

    const clonedOptions = [...localOptions];
    const updatedOption = clonedOptions.find(x => x.value == optionId);

    if (updatedOption) {
      updatedOption.checked = isChecked;
    }

    setLocalOptions(clonedOptions);

    // Send the selected options to parent component (HierarchySelectorContainer)
    EventEmitter.dispatch("selectedOptions", {
      uuid,
      selectedOptions: clonedOptions.filter(x => x.checked).map(x => x.value)
    });
  };

  return (
    <GridWrapper id={`hierarchy-option-selector-${hierarchySelectorCounter}`}>
      <GridItem size={"1/1"}>
        {localOptions &&
          localOptions.length > 0 &&
          Object.keys(localOptions || []).map(option => {
            let uuid = uuidGenerator();
            let keyValues = localOptions[option];
            let unique_name = keyValues.value + "-" + uuid;
            let optionNameHierarchyCode = (keyValues.label + " ("
                  + (
                      (keyValues.hierarchyCode != null) ? keyValues.hierarchyCode : "NA"
                  )
                  + ") ")

            return (
              <Checkbox
                id={keyValues.value}
                key={uuid}
                name={unique_name}
                label={optionNameHierarchyCode}
                onChange={e => handleCheckbox(e)}
                checked={keyValues.checked}
                inline
              />
            );
          })}
      </GridItem>
    </GridWrapper>
  );
};

export default HierarchySelectorOption;

HierarchySelectorOption.propTypes = {
  uuid: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  hierarchySelectorCounter: PropTypes.number.isRequired
};
