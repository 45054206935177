import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Card,
  SelectField,
  Label,
  InputGroup
} from "luna-react";

import "../../index.scss";

const SortedTable = ({
  id,
  columnIds,
  data,
  onClick,
  generateReport,
  reverseMapping,
  isEditRowActive,
  onEditToggle,
  diableReportGeneration
}) => {
  const [columnOrdering] = useState({
    ASCENDING: "ascending",
    DESCENDING: "descending"
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numOfPages, setNumOfPages] = useState(0);

  const [initialState, setInitialState] = useState({
    currentPage: 1,
    sortedBy: {
      column: Object.keys(columnIds)[0],
      direction: columnOrdering.ASCENDING
    }
  });

  useEffect(() => {
    if (data) {
      setNumOfPages(Math.ceil(data.length / rowsPerPage));
    }
    // setCurrentPage(1);
    window.scrollTo(0, 0);
  }, [data, rowsPerPage]);

  const getSort = sortedBy => (a, b) => {
    const key = columnIds[sortedBy.column];
    const direction = sortedBy.direction === columnOrdering.ASCENDING ? 1 : -1;
    if (a[key] !== null && b[key] !== null) {
      return a[key].toLowerCase() > b[key].toLowerCase()
        ? direction
        : b[key].toLowerCase() > a[key].toLowerCase()
        ? -direction
        : 0;
    }
  };

  const pagination = page => [
    (page - 1) * rowsPerPage,
    parseInt((page - 1) * rowsPerPage) + parseInt(rowsPerPage)
  ];
  
  const handlePageSize = event => {
    let pageSize = event.target.value;
    setRowsPerPage(pageSize);
  }
  return (
    <Card>    
      <TableContainer sortable responsive id={id}>
        <TableHeader sortLabel>
          <TableHeaderRow>
            {Object.keys(columnIds).map(column =>
              column !== "Action" ? (
                <TableHeaderCell
                  key={column}
                  sortDirection={
                    initialState.sortedBy.column === column
                      ? initialState.sortedBy.direction
                      : undefined
                  }
                  onSort={ascending =>
                    setInitialState({
                      sortedBy: {
                        column,
                        direction: ascending
                          ? columnOrdering.DESCENDING
                          : columnOrdering.ASCENDING
                      }
                    })
                  }
                >
                  {column}
                </TableHeaderCell>
              ) : (
                <TableHeaderCell key={column} className={diableReportGeneration?"table-header-action":""}>{column}</TableHeaderCell>
              )
            )}
          </TableHeaderRow>
        </TableHeader>
        {data.length > 0 && (
          <TableBody>
            {data
              .sort(getSort(initialState.sortedBy))
              .slice(...pagination(currentPage))
              .map(row => {
                return (
                  <TableRow
                    key={row.id}
                    className={[
                      !row.isActive
                        ? isEditRowActive === row.id
                          ? "luna-table-edit-row"
                          : "luna-table-inactive-user-row"
                        : "",
                      row.isActive
                        ? isEditRowActive === row.id
                          ? "luna-table-edit-row"
                          : ""
                        : ""
                    ].join(" ")}
                  >
                    {Object.keys(row || []).map(key => {
                      if (key !== "id" && key !== "isActive") {
                        var value = row[key];
                        return (
                          <TableCell className="table-max-width" key={Math.random()}>{value}</TableCell>
                        );
                      }
                    })}
                    <TableCell className="action-button">
                      <InputGroup>
                      <Button
                        id="edit-row"
                        onClick={() => {
                          onClick(row.id);
                          onEditToggle(row.id);
                        }}>
                        Edit
                      </Button>
                      {diableReportGeneration ? (
                      <Button as = {InputGroup.Append}
                        id="mapping-report"
                        onClick={() => {
                          generateReport(row.id);
                        }}>
                        Report
                      </Button>
                      ):null}
                      {diableReportGeneration ? (
                      <Button as = {InputGroup.Append}
                        id="reverse-mapping"
                        onClick={() => {
                          reverseMapping(row.id);
                        }}>
                        Mapping
                      </Button>
                      ):null}
                      </InputGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        )}
      </TableContainer>
      <br/>
      <InputGroup className = "page-size-block">
        <Label htmlFor="" className = "page-size-label">Page Size:&nbsp;&nbsp;</Label>
        <SelectField as={InputGroup.Append}
          name = "page-size"
          className = "page-size-dropdown"
          options = {[
                    {label: '05', value: 5},
                    {label: '10', value: 10},
                    {label: '20', value: 20},
                    {label: '50', value: 50}
                    ]}
          defaultValue = {10}
          onChange = {handlePageSize}
        />
      
      <Pagination as = {InputGroup.Append}
        className="ln-u-text-align-right"
        showPages
        showFirstAndLast
        linkElement="button"
        current={currentPage}
        total={numOfPages}
        onClick={() => {
          onEditToggle(isEditRowActive);
        }}
        onChange={(page, e) => {
          e.preventDefault();
          setCurrentPage(page);
        }}
      />
      </InputGroup>
      <br/>
      {/* dummy element to rectify float: right property side-effects applied to className page-size-block */}
      <InputGroup></InputGroup> 
    </Card>
  );
};

export default SortedTable;
