const strings = {
  common: {
    apiGetErrorMessage:
      "An error occurred while trying to fetch data. Please try again.",
    apiDownloadErrorMessage:
      "An error occurred while trying to download document. Please try again",
    apiSaveSuccessMessage: "Successfully saved.",
    noUpdatesToSave: "No updates.",
    apiSaveErrorMessage:
      "An error occurred while trying to save data. Please try again.",
    noDataMessage: "No data matching your search criteria.",
    noProductClassSelected: "No product class is selected"
  }
};

export default strings;
