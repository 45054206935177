import React, { useState, Fragment, useEffect } from "react";
import { Card, Checkbox, FilledButton, GridWrapper, GridItem } from "luna-react";
import { useExtendedProductMappingReport } from "../../../api/reverseMappingService";
import strings from "../../../res/strings";
import * as utils from "../../shared/Utils";
import LoadingNotification from "../../shared/LoadingNotification";

const ReversedMappingReport = () => {

  const [
    { extendedReportData, isDownloading, isDownloaded, isDownloadError, setExtendedMappingReportData }
  ] = useExtendedProductMappingReport();

  const initialform = {
    requirement: true,
    generalDocument: false
  };
  const [form, setForm] = useState(initialform);

  useEffect(()=>{
    if(extendedReportData){
      fileDownload();
    }
  },[extendedReportData])

  useEffect(() => {
    if (isDownloadError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isDownloadError]);

  const fileDownload = () => {
    const blobType = { type: "application/vnd.ms-excel" };
    const filename = `Extended Mapping - ${new Date().toLocaleString()}`;
    const filenameType = ".xlsx";
 
    const file = new Blob([extendedReportData], blobType);
 
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
 
    let a = document.createElement("a");
 
    a.download = filename.concat(filenameType);
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();
 
    setTimeout(function() {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };
  
  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  }

  const validateDownload = () =>{
    let passed = false;  
    if(form.requirement || form.generalDocument){
        passed = true;
    }
    return passed;
  }
  const handleDownload = () => {
    if(validateDownload()){
      const extendedMapping = Object.assign({
        regulationStatus: form.requirement,
        generalDocumentStatus: form.generalDocument
      });
      setExtendedMappingReportData(extendedMapping)
    }
  }

  return(  
    <GridWrapper>
      {isDownloading && !isDownloaded && (
        <LoadingNotification notificationOpen={true} />
      )}
      <GridItem size="1/4">
        <Checkbox
          id="requirement-checkbox"
          name="requirement"
          label="Requirements"
          inline
          checked={form.requirement}
          onChange={event => handleChange(event)}
        />   
      </GridItem>
      <GridItem size="1/4">
        <Checkbox
          id="general-document-checkbox"
          name="generalDocument"
          label="General Documents"
          inline
          checked={form.generalDocument}
          onChange={event => handleChange(event)}
        />   
      </GridItem>
      <GridItem size="1/4">
      </GridItem>
      <GridItem size="1/4">
        <FilledButton
          id="report-mapping--download-button"
          onClick={() => handleDownload()}
          fullWidth
        >
          Download
        </FilledButton>
      </GridItem>
    </GridWrapper>     
  );
}

export default ReversedMappingReport;