import React, { Fragment, useState, useEffect } from "react";
import { api } from "../../api/api";
import {
  GridWrapper,
  GridItem,
  ProgressIndicator,
  ProgressBar,
  Card
} from "luna-react";
import HierarchyList from "./HierarchyList";
import jp from "jsonpath";
import { HierarchyType } from "../../res/enums";
import * as utils from "../shared/Utils";
import ActiveStatusFilter from "../shared/ActiveStatusFilter";
import strings from "../../res/strings";

import { useGetProductHierarchies } from "../../api/productHierarchyService";

const ProductHierarchy = () => {
  const [statusFilter, setStatusFilter] = useState(true);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState([]);
  const [productClass, setProductClass] = useState([]);
  const [option, setOption] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState({
    categoryId: null,
    subCategoryId: null,
    productClassId: null,
    optionId: null
  });

  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [
    { data, isFetching, isError },
    setQueryData
  ] = useGetProductHierarchies();

  useEffect(() => {
    if (isError) {
      utils.toastError(strings.common.apiGetErrorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isFetching) {
      setIsLoading(isFetching);
    }
  }, [isFetching]);

  useEffect(() => {
    setQueryData(`isActive=${statusFilter}&ts=${Math.random()}`);
  }, [setQuery]);

  useEffect(() => {
    if (data) {
      if (data.length == 0) {
        utils.toastInfo(strings.common.noDataMessage);
      } else {
        renderData(data);
        setIsLoading(false);
      }
    }
  }, [data]);

  const renderData = categoryData => {
    let localCategoryData = null;
    let selectedItemStillAvailable = false;

    if (selectedHierarchy.categoryId && categoryData != null) {
      localCategoryData = categoryData.map(x => {
        if (x.id === selectedHierarchy.categoryId) {
          selectedItemStillAvailable = true;
          return { ...x, selected: true };
        } else {
          return { ...x, selected: false };
        }
      });
    } else {
      localCategoryData = categoryData;
    }

    setCategory(localCategoryData);

    if (!selectedItemStillAvailable) {
      setSubCategory([]);
      setProductClass([]);
      setOption([]);
      return;
    }

    selectedItemStillAvailable = false;

    if (selectedHierarchy.categoryId) {
      let subCategoryItems = jp.query(
        localCategoryData,
        `$[?(@.id==${selectedHierarchy.categoryId})].subCategory`
      )[0];

      if (selectedHierarchy.subCategoryId && subCategoryItems != null) {
        subCategoryItems = subCategoryItems.map(x => {
          if (x.id === selectedHierarchy.subCategoryId) {
            selectedItemStillAvailable = true;
            return { ...x, selected: true };
          } else {
            return { ...x, selected: false };
          }
        });
      }

      setSubCategory(subCategoryItems);
    }

    if (!selectedItemStillAvailable) {
      setProductClass([]);
      setOption([]);
      return;
    }

    selectedItemStillAvailable = false;

    if (selectedHierarchy.subCategoryId) {
      let productClassItems = jp.query(
        localCategoryData,
        `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedHierarchy.subCategoryId})].productClass`
      )[0];

      if (selectedHierarchy.productClassId && productClassItems != null) {
        productClassItems = productClassItems.map(x => {
          if (x.id === selectedHierarchy.productClassId) {
            selectedItemStillAvailable = true;
            return { ...x, selected: true };
          } else {
            return { ...x, selected: false };
          }
        });
      }

      setProductClass(productClassItems);
    }

    if (!selectedItemStillAvailable) {
      setOption([]);
      return;
    }

    if (selectedHierarchy.productClassId) {
      let optionItems = jp.query(
        localCategoryData,
        `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedHierarchy.subCategoryId})].productClass[?(@.id==${selectedHierarchy.productClassId})].option`
      )[0];

      if (selectedHierarchy.optionId && optionItems != null) {
        optionItems = optionItems.map(x => {
          if (x.id === selectedHierarchy.optionId) {
            return { ...x, selected: true };
          } else {
            return { ...x, selected: false };
          }
        });
      }

      setOption(optionItems);
    }
  };

  const handleItemSelection = (hierarchyTypeValue, itemId) => {
    switch (hierarchyTypeValue) {
      case HierarchyType.CATEGORY:
        setCategory(
          category.map(x => {
            if (x.id === itemId) {
              return { ...x, selected: true };
            } else {
              return { ...x, selected: false };
            }
          })
        );
        setSelectedHierarchy({
          categoryId: itemId,
          subCategoryId: null,
          productClassId: null,
          optionId: null
        });
        const subCategoryItems = jp.query(
          category,
          `$[?(@.id==${itemId})].subCategory`
        )[0];
        if(subCategoryItems==null){
          setSubCategory([]);
        }
        else{
          setSubCategory(subCategoryItems);
        }
        setProductClass([]);
        setOption([]);
        break;
      case HierarchyType.SUBCATEGORY:
        setSubCategory(
          subCategory.map(x => {
            if (x.id === itemId) {
              return { ...x, selected: true };
            } else {
              return { ...x, selected: false };
            }
          })
        );
        setSelectedHierarchy({
          ...selectedHierarchy,
          subCategoryId: itemId,
          productClassId: null,
          optionId: null
        });
        const productClassItems = jp.query(
          category,
          `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${itemId})].productClass`
        )[0];
        if(productClassItems==null){
          setProductClass([]);
        }
        else{
          setProductClass(productClassItems);
        }
        setOption([]);
        break;
      case HierarchyType.PRODUCTCLASS:
        setProductClass(
          productClass.map(x => {
            if (x.id === itemId) {
              return { ...x, selected: true };
            } else {
              return { ...x, selected: false };
            }
          })
        );
        setSelectedHierarchy({
          ...selectedHierarchy,
          productClassId: itemId,
          optionId: null
        });
        const optionItems = jp.query(
          category,
          `$[?(@.id==${selectedHierarchy.categoryId})].subCategory[?(@.id==${selectedHierarchy.subCategoryId})].productClass[?(@.id==${itemId})].option`
        )[0];
        if(optionItems==null){
          setOption([]);
        }
        else{
          setOption(optionItems);
        }
        break;
      case HierarchyType.OPTION:
        setOption(
          option.map(x => {
            if (x.id === itemId) {
              return { ...x, selected: true };
            } else {
              return { ...x, selected: false };
            }
          })
        );
        setSelectedHierarchy({ ...selectedHierarchy, optionId: itemId });
        break;
      default:
        break;
    }
  };

  const refreshData = status => {
    if (status != null) {
      setStatusFilter(status);
    } else {
      status = statusFilter;
    }

    setQueryData(`isActive=${status}&ts=${Math.random()}`);
  };

  return (
    <Fragment>
      <GridWrapper gutterSize="zero">
        <GridItem size="1/1" className="ln-u-soft no-bottom-padding">
          <Card className="status-container">
            <h5 className="label">Status:</h5>
            <ActiveStatusFilter
              activeStatus={true}
              inactiveStatus={false}
              onChange={status => refreshData(status)}
            />
            {isLoading ? (
              <ProgressIndicator
                className="progress-indicator"
                loading
                preventFocus
              >
                <ProgressBar size="small" />
              </ProgressIndicator>
            ) : null}
          </Card>
        </GridItem>
      </GridWrapper>

      <GridWrapper gutterSize="zero">
        <GridItem size="1/4" className="ln-u-soft no-padding-top">
          <HierarchyList
            key={1}
            id={1}
            hierarchyType={HierarchyType.CATEGORY}
            parentId={null}
            heading={"Category"}
            parentHeading={""}
            items={category}
            handleItemSelection={handleItemSelection}
            notifyFormSubmissionDone={refreshData}
          />
        </GridItem>
        <GridItem size="1/4" className="ln-u-soft no-padding-top">
          <HierarchyList
            key={2}
            id={2}
            parentId={selectedHierarchy.categoryId}
            hierarchyType={HierarchyType.SUBCATEGORY}
            heading={"Sub Category"}
            parentHeading={"Category"}
            items={subCategory}
            handleItemSelection={handleItemSelection}
            notifyFormSubmissionDone={refreshData}
          />
        </GridItem>
        <GridItem size="1/4" className="ln-u-soft no-padding-top">
          <HierarchyList
            key={3}
            id={3}
            hierarchyType={HierarchyType.PRODUCTCLASS}
            parentId={selectedHierarchy.subCategoryId}
            heading={"Class"}
            parentHeading={"Sub Category"}
            items={productClass}
            handleItemSelection={handleItemSelection}
            notifyFormSubmissionDone={refreshData}
          />
        </GridItem>
        <GridItem size="1/4" className="ln-u-soft no-padding-top">
          <HierarchyList
            key={4}
            id={4}
            hierarchyType={HierarchyType.OPTION}
            parentId={selectedHierarchy.productClassId}
            heading={"Option"}
            parentHeading={"Class"}
            items={option}
            handleItemSelection={handleItemSelection}
            notifyFormSubmissionDone={refreshData}
          />
        </GridItem>
      </GridWrapper>
    </Fragment>
  );
};

export default ProductHierarchy;
