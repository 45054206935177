import { api } from "../api/api";

const apiUrl = `${window.appConfig.rootUrl}/ReverseMapping/`;

export function useGetReverseMappings() {
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(apiUrl);
  
  return [{ 
    mappingData: data, 
    isFetching: isLoading, 
    mappingError: isError }, 
    {setReverseMappingQuery: setQuery}];
}

export function useProductMappingReport() {
  const [
    { data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ] = api.useDataDownload(`${apiUrl}MappingsReport`);

  return [
    {
      reportData: data,
      isDownloading,
      isDownloaded,
      isDownloadError,
      setMappingReportData: setFormData
    }
  ];
}

export function useExtendedProductMappingReport() {
  const [
    { data, isDownloading, isDownloaded, isDownloadError },
    setFormData
  ] = api.useDataDownload(`${apiUrl}ExtendedReport`);

  return [
    {
      extendedReportData: data,
      isDownloading,
      isDownloaded,
      isDownloadError,
      setExtendedMappingReportData: setFormData
    }
  ];
}