import React from "react";
import {
  NotificationList,
  NotificationItem,
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage
} from "luna-react";

import "../../index.scss";

const LoadingNotification = ({ notificationOpen }) => {
  return (
    <>
      <NotificationList
        open={notificationOpen}
        className="loading-notification"
      >
        <NotificationItem>
          <ProgressIndicator loading preventFocus>
            <ProgressSpinner />
            <ProgressMessage className="ln-u-margin-left">
              Loading...
            </ProgressMessage>
          </ProgressIndicator>
        </NotificationItem>
      </NotificationList>
    </>
  );
};

export default LoadingNotification;
