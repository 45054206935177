import React, { useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { CloseButton } from "luna-react";
import "./popup.scss";

const PopupComponent = ({show,handleClose, displayContent}) => {
  return(
    <Popup open={show} closeOnDocumentClick={false}>
      <div className="modal">
      <div className="header"> New document added </div>
        <CloseButton  onClick={handleClose}/>
        <div className="content">
        <p>{displayContent}</p>
        </div>
          <button
            id="close-popup"
            type="button"
            className="ln-c-button ln-c-button--filled"
            onClick={handleClose}
          >Close
          </button>
      </div>
    </Popup>
  );
}

PopupComponent.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  displayContent: PropTypes.string.isRequired
};
export default PopupComponent;