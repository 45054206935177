import { useState, useEffect, useReducer } from "react";
import {
  authorisedFetch,
  redirectToLogin,
  getToken
} from "@jsainsburyplc/colleague-auth";
import HttpStatus from "../res/HttpStatus";

const logging = {
  log(error) {
    console.log(error);
  }
};

const responseNoContent = "No Content";

const getAuthFetch = dataUrl =>
  authorisedFetch(dataUrl, {})
    .then(async response => {
      if (!response.ok) {
        if (response.status === 403) {
          console.log(dataUrl + " request returned 403 forbidden");
        } else {
          let err = await response.json();
          throw Error(`Error: ${err.Message} Trace Id ${err.TraceId}`);
        }
      }
      if (response.ok && response.statusText !== responseNoContent) {
        return response.json();
      }
      return null;
    })
    .catch(error => {
      logging.log(dataUrl);
      logging.log(error);
      throw error;
    });

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error();
  }
};

function useDataFetch(url, initialQuery) {
  const [query, setQuery] = useState(initialQuery);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: null
  });

  useEffect(() => {
    if (typeof query === "undefined") {
      return;
    }

    let didCancel = false;

    const fetchData = () => {
      dispatch({ type: "FETCH_INIT" });
      try {
        authorisedFetch(`${url}?${query}`, {})
          .then(response => {
            if (response.ok) {
              return response.json();
            } else if (response.status === 401) {
              return redirectToLogin();
            } else {
              throw new Error("The response returns an error");
            }
          })
          .then(data => {
            if (!didCancel) {
              dispatch({ type: "FETCH_SUCCESS", payload: data });
            }
          })
          .catch(() => {
            if (!didCancel) {
              dispatch({ type: "FETCH_FAILURE" });
            }
          });
      } catch (error) {
        if (error.message === "Token expired") {
          return redirectToLogin();
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [query]);

  return [state, setQuery];
}

function useDataSave(url, initialData) {
  const [data, setData] = useState(initialData);

  const [state, dispatch] = useReducer(dataSaveReducer, {
    isSaved: false,
    isSaving: false,
    isError: false,
    errorCode: null,
    errorMessage: null
  });

  useEffect(() => {
    if (typeof data === "undefined") {
      return;
    }

    const saveData = () => {
      dispatch({ type: "SAVE_INIT" });

      try {
        authorisedFetch(url + (data.id || ""), {
          method: data.id ? "PUT" : "POST",
          headers: {
            Authorization: getToken(),
            "content-type": "application/json-patch+json"
          },
          body: JSON.stringify(data)
        })
          .then(response => {
            if (response.status === 401) {
              return redirectToLogin();
            } else if (!response.ok) {
              throw response;
            } else {
              return data.id ? response : response.json();
            }
          })
          .then(data => {
            dispatch({ type: "SAVE_SUCCESS", payload: data });
          })
          .catch(error => {
            if (error.status === HttpStatus.STATUS_409_CONFLICT) {
              error.text().then(errorMessage => {
                dispatch({
                  type: "SAVE_FAILURE_ERROR",
                  errorCode: HttpStatus.STATUS_409_CONFLICT,
                  errorMessage
                });
              });
            } else {
              dispatch({
                type: "SAVE_FAILURE"
              });
            }
          });
      } catch (error) {
        if (error.message === "Token expired") {
          redirectToLogin();
        }
      }
    };

    saveData();
  }, [data]);

  return [state, setData];
}

const dataSaveReducer = (state, action) => {
  switch (action.type) {
    case "SAVE_INIT":
      return { ...state, isSaved: false, isSaving: true, isError: false };
    case "SAVE_SUCCESS":
      return {
        ...state,
        isSaved: true,
        isSaving: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: action.payload
      };
    case "SAVE_FAILURE_ERROR":
      return {
        ...state,
        isSaved: false,
        isSaving: false,
        isError: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage
      };
    case "SAVE_FAILURE":
      return {
        ...state,
        isSaved: false,
        isSaving: false,
        isError: true,
        errorCode: null,
        errorMessage: null
      };
    default:
      throw new Error();
  }
};

function useDataDownload(url, initialData) {
  const [data, setData] = useState(initialData);

  const [state, dispatch] = useReducer(dataDownloadReducer, {
    isDownloading: false,
    isDownloaded: false,
    isDownloadError: false
  });

  useEffect(() => {
    if (typeof data === "undefined") {
      return;
    }

    const downloadData = () => {
      dispatch({ type: "DOWNLOAD_INIT" });

      try {
        authorisedFetch(url, {
          method: "POST",
          headers: {
            Authorization: getToken(),
            "content-type": "application/json-patch+json"
          },
          body: JSON.stringify(data)
        })
          .then(response => {
            if (response.status === 401) {
              return redirectToLogin();
            } else if (!response.ok) {
              throw response;
            } else {
              return response.blob();
            }
          })
          .then(data => {
            dispatch({ type: "DOWNLOAD_SUCCESS", payload: data });
          })
          .catch(error => {
            dispatch({
              type: "DOWNLOAD_FAILURE"
            });
          });
      } catch (error) {
        if (error.message === "Token expired") {
          redirectToLogin();
        }
      }
    };

    downloadData();
  }, [data]);

  return [state, setData];
}

const dataDownloadReducer = (state, action) => {
  switch (action.type) {
    case "DOWNLOAD_INIT":
      return {
        ...state,
        isDownloading: true,
        isDownloaded: false,
        isDownloadError: false
      };
    case "DOWNLOAD_SUCCESS":
      return {
        ...state,
        isDownloading: false,
        isDownloaded: true,
        isDownloadError: false,
        data: action.payload
      };
    case "DOWNLOAD_FAILURE":
      return {
        ...state,
        isDownloading: false,
        isDownloaded: false,
        isDownloadError: true
      };
    default:
      throw new Error();
  }
};

const api = {
  getAuth(url) {
    return getAuthFetch(url);
  },
  useDataFetch(url, initialQuery) {
    return useDataFetch(url, initialQuery);
  },
  useDataSave(url, data) {
    return useDataSave(url, data);
  },
  useDataDownload(url, data) {
    return useDataDownload(url, data);
  }
};

export { api };
