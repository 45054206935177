import React, { useState } from "react";
import {
  SearchField,
  Button,
  GridWrapper,
  GridItem,
  Form,
  Card,
  FilledButton
} from "luna-react";
import { Search } from "luna-icons";
import ActiveStatusFilter from "./ActiveStatusFilter";

const SearchBar = ({ placeholder, onSearch, downloadButton, handleDocumentDownload }) => {
  const [form, setForm] = useState({ term: "", status: true });

  const handleFormChange = (event, status) => {
    if (event != null) {
      setForm({ ...form, term: event.target.value.replace(/#/g, "") });
    } else {
      setForm({ ...form, status: status });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    setForm({ ...form, term: form.term.trim() });

    onSearch(form.term.trim(), form.status);
  };

  const handleBlur = event => {
    setForm({ ...form, term: event.target.value.replace(/#/g, "").trim() });
  };

  return (
    <Card className="card-padding">
      <GridWrapper>
        <GridItem size={downloadButton?"3/5":"4/5"} element="li">
          <Form onSubmit={handleSubmit} type="submit">
            <SearchField
              id="search-input"
              name="term"
              label="SearchUsers"
              hideLabel
              className="no-bottom-margin"
              value={form.term}
              onChange={event => handleFormChange(event, null)}
              placeholder={placeholder}
              onBlur={handleBlur}
              hasAction
              action={
                <Button id="search-button" onClick={handleSubmit}>
                  <Search />
                </Button>
              }
            />
          </Form>
        </GridItem>
        {downloadButton?
        (<GridItem size={"1/5"} element="li">
          <FilledButton
            id="download-report"
            onClick = {handleDocumentDownload}
            fullWidth
          >
            Download
          </FilledButton>
        </GridItem>
        ):null
        }
        <GridItem size={"1/5"} element="li">
          <fieldset className="ln-c-form-group no-bottom-margin">
            <ActiveStatusFilter
              id="search-status"
              activeStatus={true}
              inactiveStatus={false}
              onChange={status => handleFormChange(null, status)}
            />
          </fieldset>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default SearchBar;
