import { api } from "../api/api";

const apiUrl = `${window.appConfig.rootUrl}/Regulations/`;
const documentUrl = `${apiUrl}DocumentIdentifierName`;

export function useGetRegulations() {
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(apiUrl);

  return [{ data, isLoading, isError }, setQuery];
}

export function useGetDocumentIdentifier(){
  
  const [{ data, isLoading, isError }, setQuery] = api.useDataFetch(documentUrl);

  return [{ data, isLoading, isError }, setQuery];
}

export function useSaveRegulation() {
  const [
    { isSaved, isError, errorCode, errorMessage },
    setFormData
  ] = api.useDataSave(apiUrl);

  return [{ isSaved, isError, errorCode, errorMessage }, setFormData];
}
